import { TextElementBox, DocumentEditor, ParagraphWidget } from '@syncfusion/ej2-react-documenteditor';
export const parseJWT = (token: any) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null
  }
};

export const getExtension = (fileName: any) => {
  return fileName.split('.').pop().toLowerCase();
}

export const getUrlsFromText = (text: string): string[] => {
  const urls = text.split(",")
  return urls || [];
}

export const isWebsite = (text: string): boolean => {
  // Check for common website protocols
  if (text.startsWith("http://") || text.startsWith("https://")) {
    return true;
  }

  // Check for common top-level domains
  const tlds: string[] = [".com", ".org", ".net", ".edu", ".gov"];
  const lastPart: string = text.split(".").pop()!;
  if (tlds.includes("." + lastPart)) {
    return true;
  }

  // Use a regular expression to match URLs
  const urlRegex: RegExp = /\b(http|https):\/\/[^\s]+\b/;
  if (urlRegex.test(text)) {
    return true;
  }

  return false;
}

export const getDateLabel = (date: Date, language: string): string => {
  const today = new Date();
  const paramDate = new Date(date);

  // Set the time to midnight for comparison
  today.setHours(0, 0, 0, 0);
  paramDate.setHours(0, 0, 0, 0);

  if (paramDate.getTime() === today.getTime()) {
    return language == 'no' ? 'I dag' : 'Today';
  } else if (paramDate.getTime() === today.getTime() - 86400000) {
    return language == 'no' ? 'I går' : 'Yesterday';
  } else if (paramDate.getTime() === today.getTime() + 86400000) {
    return language == 'no' ? 'I morgen' : 'Tomorrow';
  } else {
    const options: any = { day: 'numeric', month: 'long' };
    return paramDate.toLocaleDateString(undefined, options);
  }
};

export const getDateInfo = (date: Date): any => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return { "year": year, "month": month, "day": day };
};

export const getColorByScore = (score: number, cpvCodes, settingCPVCodes) => {
  const isExactMatch = cpvCodes.some(item => settingCPVCodes.includes(item)); // Exact CPV code match
  const isSeriesMatch = cpvCodes.some(item =>
    settingCPVCodes.some(settingItem => item.slice(0, 2) === settingItem.slice(0, 2)) // Same CPV series
  );
  if (isExactMatch && score > 6) {
    // Green: Exact CPV code match AND relevance score >6
    return 'bg-[#43A440]';
  } else if (isSeriesMatch && score > 7) {
    // Green: Same CPV series AND relevance score >7
    return 'bg-[#43A440]';
  } else if (isSeriesMatch || (score >= 4 && score <= 6)) {
    // Yellow: Same CPV series OR relevance score between 4-6
    return 'bg-[#FFC107]';
  } else {
    // Red: Different CPV series AND relevance score <4
    return 'bg-[#E3343A]';
  }
};


export const getColorOfCompilanceScore = (score: number) => {
  if (score == null) {
    return ''
  }
  if (score == 10) {
    return 'bg-[#43A440]';
  } else if (score >= 6) {
    return 'bg-[#FFC107]';
  } else {
    return 'bg-[#E3343A]';
  }
};


export const getTextFromCell = (cell: any) => {
  let cellText = '';
  for (let h = 0; h < cell?.childWidgets?.length; h++) {
    const lineWidgets = cell?.childWidgets[h]?.childWidgets ? cell?.childWidgets[h]?.childWidgets : []
    for (let i = 0; i < lineWidgets.length; i++) {
      const textElementboxes = lineWidgets[i].children
      for (let j = 0; j < textElementboxes.length; j++)
        cellText += textElementboxes[j].text ? textElementboxes[j].text : ''
    }
  }
  return cellText
}

export const removeTextofCell = (cell: any) => {
  const lineWidgets = cell?.childWidgets[0]?.childWidgets ? cell?.childWidgets[0]?.childWidgets : []
  for (let i = 0; i < lineWidgets.length; i++) {
    const textElementboxes = lineWidgets[i].children
    for (let j = 0; j < textElementboxes.length; j++)
      textElementboxes[j].text = ''
  }
}

export const getDescriptionFromRow = (row: any) => {
  const cellWidgets = row?.childWidgets
  const length = cellWidgets.length
  for (let i = 0; i < length; i++) {
    let cellText = ''
    cellText = getTextFromCell(cellWidgets[i])
    if (cellText.length > 10 && (i + 1 < length)) {
      return cellText
    }
  }
  return ""
}


export const getRequirementIndex = (row: any) => {
  const cellWidgets = row?.childWidgets
  const length = cellWidgets.length
  for (let i = 0; i < length; i++) {
    let cellText = ''
    cellText = getTextFromCell(cellWidgets[i])
    if (cellText.length > 10 && (i + 1 < length)) {
      return i
    }
  }
  return 0
}
export const getRemainingDaysAndHours = (tenderDate: string): any => {
  if (tenderDate == "None") {
    return null
  }
  if (tenderDate) {
    const remainingTime = new Date(tenderDate).getTime() - new Date().getTime();
    const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return { remainingDays, remainingHours };
  }

  return null;
};

export const getFirstSentence = (text: string): string => {
  const regex = /^[^.!?\n]+[.!?\n]/; // Regular expression to match the first sentence
  const match = text.match(regex);
  return match ? match[0] : ''; // Return the matched sentence or an empty string if no match found
};


export const splitTextIntoChunks = (text: string, chunkSize: number): string[] => {
  const chunks: string[] = [];
  for (let i = 0; i < text.length; i += chunkSize) {
    chunks.push(text.slice(i, i + chunkSize));
  }
  return chunks;
}


export const getTenderLength = (tenderList: any[]): number => {
  return tenderList.reduce((totalLength, item) => {
    if (item?.isFolderDeleted !== 1) {
      return totalLength + 1;
    }
    return totalLength;
  }, 0);
}

// Define the utility function
export const getExcelCellText = async (spreadsheet: any, cellKey: any) => {
  try {
    // Fetch data from the given cell key
    const data = await spreadsheet.getData(`${cellKey}`);

    // Check if the data exists and retrieve the cell value
    if (data && data.get(cellKey)) {
      let text = data.get(cellKey).value
      return text != undefined ? text : '';  // Return the value of the cell
    } else {
      return '';  // Return null if the cell has no data
    }
  } catch (error) {
    console.error(`Error retrieving cell data for ${cellKey}:`, error);
    return '';  // Return null in case of an error
  }
};

export const getColumnLetter = (colIndex) => {
  let letter = '';
  while (colIndex >= 0) {
    letter = String.fromCharCode((colIndex % 26) + 65) + letter;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return letter;
};

export const getDescriptionFromExcelRow = async (spreadsheet: any, cellRow: any, totalColumns: number) => {
  for (let i = 0; i < totalColumns; i++) {
    // Use custom function to get column letter (e.g., 0 -> "A", 1 -> "B", ...)
    console.log();
    const cellKey = `${getColumnLetter(i)}${cellRow}`
    let cellText = ''
    cellText = await getExcelCellText(spreadsheet, cellKey)
    if (cellText.length > 10 && (i + 1 < totalColumns)) {
      return cellText
    }
  }
  return ""
};
