import React, { useRef, useEffect, useState } from 'react'
import "../../../App.css"
import $ from 'jquery'
import {
  DocumentEditorContainerComponent, Toolbar, CustomToolbarItemModel, ImageFormat, TableWidget, TableRowWidget, TextPosition,
} from '@syncfusion/ej2-react-documenteditor';
import { isRowSelected, SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import {
  PdfViewerComponent, Toolbar as PDFToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject, AllowedInteraction,
} from '@syncfusion/ej2-react-pdfviewer';
import { getExtension, parseJWT, isWebsite, getTextFromCell, getDescriptionFromRow, removeTextofCell, getFirstSentence, getColorOfCompilanceScore, getRequirementIndex, splitTextIntoChunks, getExcelCellText, getDescriptionFromExcelRow, } from '../../../utils';
import { ClickEventArgs, MenuItemModel } from '@syncfusion/ej2-navigations';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  PdfSection,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import { useTranslation } from 'react-i18next';
import { showSpinner, hideSpinner, createSpinner } from '@syncfusion/ej2-popups';
import i18n from '../../../i18n';
import { toast } from 'react-toastify';
import { LoadingBar } from '../../LoadingBar';
const { default: axios } = require('axios');

interface EditProps {
  document: string,
  openedDocument: string,
  documentUpdated: boolean,
  styleCenter: any,
  currentSlide: any,
  documentAction: string,
  setDocumentUpdated: any,
  tender_tab: any,
  tender_title: any,
  aiFeedbackGetAnswer: any
  previousOpenedDocument: string
  selectedDocumentList: any[]
  complianceData: any[]
  setComplianceData: any
  selectedTableData: any
  setSelectedTableData: any
  setCurrentSlide: any
  compilanceSaveClicked: boolean
  setCompilanceSaveClicked: any
  tender_id: any,
  setAutoFilling,
  autoFilling,
  companyKnowledgeBaseId: string
}

DocumentEditorContainerComponent.Inject(Toolbar);

export const Editor: React.FC<EditProps> = ({ document, openedDocument, documentUpdated, styleCenter, currentSlide, documentAction, setDocumentUpdated, tender_tab, tender_title, aiFeedbackGetAnswer, previousOpenedDocument, selectedDocumentList, complianceData, setComplianceData, selectedTableData, setSelectedTableData, setCurrentSlide, compilanceSaveClicked, setCompilanceSaveClicked, tender_id, setAutoFilling, autoFilling, companyKnowledgeBaseId }) => {
  let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
  const [editorName, setEditorName] = useState("");
  const [updatedDocument, setUpdatedDocument] = useState<any>(null);
  const [companyBaseTenderID, setCompanyBaseTenderID] = useState<any>(null);
  const [selectedText, setSelectedText] = useState('')
  const [contentChanged, setContentChanged] = useState(false)
  const { t } = useTranslation();
  let documenteditor: any;
  const spreadsheetRef = React.useRef<SpreadsheetComponent>(null);
  useEffect(() => {
    if (openedDocument != "")
      getCompanyBaseTenderID()
  }, [openedDocument])
  useEffect(() => {
    if (autoFilling == true && openedDocument != "") {
      let documentObject: any = $('#docx_container');
      if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
        const editor = documentObject[0]?.ej2_instances[0]?.documentEditor;
        setTimeout(async () => {
          await autoFillTableCell(editor);
          setAutoFilling(false)
        }, 3000);
      }
    }
  }, [autoFilling, openedDocument]);

  // Function to find and auto-fill a specific table cell
  const autoFillTableCell = async (editor: any) => {
    const pages = editor.documentHelper.pages
    var cells: any = []
    var params: any = []
    pages.forEach(async (page: any) => {
      const bodyWidgets = page.bodyWidgets[0];
      // Loop through the body to find tables
      bodyWidgets?.childWidgets.forEach(async (widget: any) => {
        if (widget instanceof TableWidget) {
          const table = widget as TableWidget;
          // Access specific rows and cells
          const targetRows: any = table?.childWidgets; // Row index 1 (2nd row)
          console.log("targetRows", targetRows)
          for (let i = 1; i < targetRows.length; i++) {
            console.log(targetRows.length)
            console.log("-------------------------------")
            const targetRow: any = targetRows[i]
            const targetCells: any = targetRow?.childWidgets; // Cell index 1 (2nd cell)
            console.log("targetCells", targetCells)
            if (targetCells.length > 0) {
              const targetCell: any = targetCells[targetCells.length - 1]
              // Set the cursor to the specific cell
              if (getTextFromCell(targetRow?.childWidgets[0]).trim() !== '' && targetRow?.childWidgets.length > 2) {
                const documentsForCompanyKnowledgebade: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document)
                let question = documentsForCompanyKnowledgebade.length > 0 ? "By referencing " + documentsForCompanyKnowledgebade.join(",") + ", Please try to make answer as text, not HTML of following requirement. \n requirement:" + getDescriptionFromRow(targetRow) : "Please try to make answer of following requirement.\n requirement:" + getDescriptionFromRow(targetRow)
                const language = i18n.language ? i18n.language : "en"
                if (language == 'no') {
                  question = documentsForCompanyKnowledgebade.length > 0 ? "Ved å referere" + documentsForCompanyKnowledgebade.join(",") + ", Vennligst prøv å svare på følgende krav.\n Svaret skal være på norsk. \n krav:" + getDescriptionFromRow(targetRow) : "Vennligst prøv å svare på følgende krav. \n Svaret skal være på norsk. \n krav:" + getDescriptionFromRow(targetRow)
                }
                let param = {
                  email: user.sub.email,
                  companyId: user.sub.companyId,
                  question: question,
                  tender_id: tender_id,
                  companyKnowledgeBaseId: companyKnowledgeBaseId,
                  otender_id: tender_id,
                  selectedDocumentList: documentsForCompanyKnowledgebade,
                  autofill: true
                }
                cells = [...cells, targetCell]
                params = [...params, param]
              }
            }
          }
        }
      });
    });
    var data: any = []
    for (let i = 0; i < cells.length; i++) {
      var targetCell = cells[i]
      let targetRow = cells[i]?.containerWidget
      editor.selection.selectTableCellInternal(targetCell)
      removeTextofCell(targetCell)
      const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
        method: 'POST',
        body: JSON.stringify(params[i]),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          'Content-Type': 'application/json',
        }
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        var regex = /([^:]*)json_data:(\{.*?\})/g;
        var match = regex.exec(chunkValue);
        if (match && match.length >= 3) {
          var beforeText = match[1];
          if (beforeText) {
            editor?.editor?.insertText(beforeText, true);
          }
          var jsonData = match[2];
        } else {
          if (!chunkValue.includes("</")) {
            editor?.editor?.insertText(chunkValue, true);
          }
        }
      }
      let requirement = getDescriptionFromRow(targetRow)
      let answer = getTextFromCell(targetCell)
      let no = getTextFromCell(targetRow?.childWidgets[0])
      data.push({ 'requirement': requirement, 'answer': answer, 'no': no })
    }
    setComplianceData(data)
  };
  const getCompanyBaseTenderID = async () => {

    const data = new FormData();
    data.append("email", user.sub.email);
    data.append("companyId", user.sub.companyId);
    const result = await axios.post(process.env.REACT_APP_API_URL + "api/getCompanyBaseTenderID", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
        "Content-Type": "application/json",
      }
    });
    setCompanyBaseTenderID(result.data.Id);
  };
  const fetchData = async (): Promise<void> => {
    const response = await fetch(process.env.REACT_APP_API_URL + 'upload/' + user.sub.companyName + '/' + tender_id + '/' + openedDocument); // fetch the remote url
    const fileBlob = await response.blob(); // convert the excel file to blob
    const file = new File([fileBlob], openedDocument); //convert the blob into file
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet) {
      spreadsheet.open({ file }); // open the file into Spreadsheet
    }
  };
  let exportDocItem: CustomToolbarItemModel = {
    prefixIcon: "e-icons e-export-word",
    tooltipText: "Export Docx",
    text: "Export Docx",
    id: "export-docx"
  };
  let exportPDFItem: CustomToolbarItemModel = {
    prefixIcon: "e-icons e-export-pdf",
    tooltipText: "Export PDF",
    text: "Export PDF",
    id: "export-pdf"
  };
  let items: any = [
    "Undo",
    "Redo",
    exportPDFItem,
    exportDocItem,
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "Comments",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "Separator",
    "Find",
    "Separator",
    "LocalClipboard",
    "RestrictEditing"
  ];

  useEffect(() => {
    if (compilanceSaveClicked) {
      let documentObject: any = $('#docx_container');
      if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
        let container: any = documentObject[0]?.ej2_instances[0]?.documentEditor
        let cells: any = selectedTableData?.selectedCells ? selectedTableData?.selectedCells : []
        let length = cells.length
        let index = 0
        for (let i = 0; i < length; i++) {
          let selectedRow = cells[i]?.containerWidget
          if (cells[i]?.index == (selectedRow?.childWidgets?.length - 1) && selectedRow?.index > 0 && getTextFromCell(selectedRow?.childWidgets[0]).trim() !== '') {
            removeTextofCell(cells[i])
            container.selection.selectTableCellInternal(cells[i])
            container.editor.insertText(complianceData[index]?.answer, true)
            index += 1
          }
        }
        setCompilanceSaveClicked(false)
        setComplianceData([])
      }
    }
  }, [compilanceSaveClicked])
  useEffect(() => {
    const resizeDocument = () => {
      let documentObject: any = $('#docx_container');
      let pdfviewerObject: any = $('#pdf_container');
      if (documentObject[0]?.ej2_instances[0]?.documentEditor)
        documentObject[0].ej2_instances[0].documentEditor.resize();
      if (pdfviewerObject[0]?.ej2_instances[0])
        pdfviewerObject[0].ej2_instances[0].updateViewerContainer();
    }
    resizeDocument();
    setTimeout(resizeDocument, 2100);
  }, [styleCenter, currentSlide])

  useEffect(() => {
    const container = $('#docx_container');
    if (container) {
      createSpinner({
        // Specify the target for the spinner to show
        target: container[0]
      });
    }
  }, [])
  useEffect(() => {
    const manageDocumentEditors = async () => {
      if (document) {
        if (getExtension(document) === "pdf") {
          setEditorName("pdf");
          var xhr = new XMLHttpRequest();
          xhr.open('GET', document + "?email=" + user.sub.email + "&openedDocument=" + openedDocument + "&documentAction=" + documentAction, true);
          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("tender_auth"));
          xhr.responseType = 'blob';
          xhr.onload = function (e) {
            if (this.status == 200) {
              var myBlob = this.response;
              var reader = new window.FileReader();
              let pdfviewerObject: any = $('#pdf_container');
              reader.readAsDataURL(myBlob);
              reader.onloadend = function () {
                let base64data = reader.result;
                if (pdfviewerObject[0].ej2_instances[0]) {
                  pdfviewerObject[0].ej2_instances[0].load(base64data);
                }
              }
            }
          };
          xhr.send();
        } else if (getExtension(document) === "docx") {
          setEditorName("docx");
          if (updatedDocument != null) {
            try {
              await axios.post(process.env.REACT_APP_API_URL + "api/updateDocumentKnowledge", {
                'email': user.sub.email,
                "doc_id": updatedDocument,
                'tab': tender_tab,
                'folder': tender_title
              }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                  "Content-Type": "application/json",
                }
              })
              setUpdatedDocument(null)
            } catch (error) {
              console.log(error)
            }
          }
          let documentObject: any = $('#docx_container');
          documentObject ? showSpinner(documentObject[0]) : null;
          var xhr = new XMLHttpRequest();
          xhr.open('GET', document + "?email=" + user.sub.email + "&openedDocument=" + openedDocument + "&documentAction=" + documentAction, true);
          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("tender_auth"));
          xhr.responseType = 'blob';
          xhr.onload = function (e) {
            if (this.status == 200) {
              let file = new File([this.response], "document");
              let httpRequest: XMLHttpRequest = new XMLHttpRequest();
              httpRequest.open('POST', 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/import', true);
              httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === 4) {
                  if (httpRequest.status === 200 || httpRequest.status === 304) {
                    documentObject = $('#docx_container')
                    if (documentObject && documentObject[0]?.ej2_instances[0]?.documentEditor) {
                      setTimeout(() => {
                        documentObject[0].ej2_instances[0].documentEditor.open(httpRequest.responseText);
                      }, 1000);
                    }
                    setInterval(function () {
                      // hideSpinner() method used hide spinner
                      hideSpinner(documentObject[0]);
                    }, 500);
                    setDocumentUpdated(false)
                  } else {
                    console.log(this.response)
                    console.error(httpRequest.response);
                  }
                }
              };
              let formData: FormData = new FormData();
              formData.append('files', file);
              httpRequest.send(formData);
            }
          };
          xhr.send();
        }
        else if (getExtension(document) === "xlsx" || getExtension(document) === "csv") {
          setEditorName("xlsx");
          fetchData();
        }
      }
    }
    if (!isWebsite(openedDocument))
      manageDocumentEditors()
  }, [document, documentUpdated])

  const onCreated = () => {

  }

  useEffect(() => {
    if (editorName == "docx") {
      let documentObject: any = $('#docx_container');
      if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
        let container: any = documentObject[0]?.ej2_instances[0]?.documentEditor
        if (documentObject) {
          setTimeout(() => {
            container = documentObject[0]?.ej2_instances[0]?.documentEditor
          }, 1000);
        }
        container.selectionChange = handleSelectionChange;

        let menuItems: MenuItemModel[] = [
          {
            text: 'Run Automagic',
            id: 'auto_fill_document',
            iconCss: 'e-icons e-de-ctnr-tableofcontent',
          },
          // {
          //   text: 'Get AI feedback',
          //   id: 'get_feedback',
          //   iconCss: 'e-icons e-de-ctnr-link',
          // },
          {
            text: 'Run BidChamp',
            id: 'compilance_checker',
            iconCss: 'e-icons e-de-ctnr-find',
          },
        ];
        // adding Custom Options
        container.contextMenu.addCustomMenu(menuItems, false);
        container.customContextMenuSelect = async (args: any) => {
          // custom Options Functionality
          let id: string = container.element.id;
          switch (args.id) {
            case id + 'get_feedback':
              if (user?.sub?.membership == "trial") {
                toast.error(t("Please upgrade your license to use this functionality"), {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: 0,
                })
                return false;
              }
              console.log('here', previousOpenedDocument)
              aiFeedbackGetAnswer(container.selection.getText(), previousOpenedDocument)
              break;
            case id + 'auto_fill_document':
              if (user?.sub?.membership == "trial") {
                toast.error(t("Please upgrade your license to use this functionality"), {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: 0,
                })
                return false;
              }
              const selectedCells = selectedTableData?.selectedCells || [];
              const documentsForCompanyKnowledgebade: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document)
              const documentsForWorkspace: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_1").map(item => item.document)

              const fetchAndHandleResponse = async (params: any, container: any) => {
                const response: any = await fetch(`${process.env.REACT_APP_API_URL}api/getAnswer`, {
                  method: 'POST',
                  body: JSON.stringify(params),
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    'Content-Type': 'application/json',
                  }
                });
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;

                while (!done) {
                  const { value, done: doneReading } = await reader.read();
                  done = doneReading;
                  const chunkValue = decoder.decode(value);
                  const regex = /([^:]*)json_data:(\{.*?\})/g;
                  const match = regex.exec(chunkValue);

                  if (match && match.length >= 3) {
                    const beforeText = match[1];
                    if (beforeText) {
                      container?.editor?.insertText(beforeText, true);
                    }
                  } else if (!chunkValue.includes("</")) {
                    container?.editor?.insertText(chunkValue, true);
                  }
                }
              };

              const generateQuestion = (tableHeaderRequirement: string, selectedRow: any, selectedCellText: string, language: string) => {
                const description = getDescriptionFromRow(selectedRow);
                const baseQuestion = language === 'no'
                  ? `Du er en smart AI-assistent designet for å generere tekst for autofylling av dokumenter. Vi trenger å fylle ut basert på dette kravet:: '${description}'.`
                  : `You are a smart AI assistant designed to generate text for auto-filling documents. We need to fill in based on this requirement:'${description}'.`;

                return `${baseQuestion}}`;
              };

              const handleAutofill = async () => {
                const language = i18n.language || "en";
                const baseParams = {
                  email: user.sub.email,
                  companyId: user.sub.companyId,
                  tender_id: tender_id,
                  otender_id: tender_id,
                  selectedDocumentList: documentsForWorkspace,
                  companyKnowledgeBaseId: companyKnowledgeBaseId,
                  documentsForCompanyKnowledgebade: documentsForCompanyKnowledgebade,
                  autofill: true
                };

                if (selectedCells.length > 0) {
                  for (const cell of selectedCells) {
                    const selectedRow = cell.containerWidget;
                    const requirementIndex = cell.index;
                    const requirementTable = selectedTableData?.selectedTables?.[0]?.childWidgets?.[0];
                    const tableHeaderRequirement = requirementTable ? getTextFromCell(requirementTable?.childWidgets[requirementIndex]) : '';

                    if (getTextFromCell(selectedRow?.childWidgets[0]).trim() !== '' && selectedRow?.childWidgets.length >= 2) {
                      const question = generateQuestion(tableHeaderRequirement, selectedRow, selectedTableData?.selectedCellTexts[cell.index], language);
                      const params = { ...baseParams, question };

                      container?.selection?.selectTableCellInternal(cell);
                      removeTextofCell(cell);

                      await fetchAndHandleResponse(params, container);
                    }
                  }
                } else {
                  const question = `${selectedText} \n Auto fill above data.`;
                  const params = { ...baseParams, question };

                  await fetchAndHandleResponse(params, container);
                }
              };

              // Call the autofill handler
              handleAutofill();


              break;
            case id + 'compilance_checker':
              if (user?.sub?.membership == "trial") {
                toast.error(t("Please upgrade your license to use this functionality"), {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: 0,
                })
                return false;
              }
              let cells: any = selectedTableData?.selectedCells ? selectedTableData?.selectedCells : []
              let data: any[] = []
              let length = cells.length
              for (let i = 0; i < length; i++) {
                let selectedRow = cells[i]?.containerWidget
                if (cells[i]?.index == (selectedRow?.childWidgets?.length - 1) && selectedRow?.index > 0 && getTextFromCell(selectedRow?.childWidgets[0]).trim() !== '' && selectedRow?.childWidgets.length > 2) {
                  let requirement = getDescriptionFromRow(selectedRow) + "\n"
                  let answer = getTextFromCell(cells[i]) + "\n"
                  let no = getTextFromCell(selectedRow?.childWidgets[0])
                  while (i + 1 < length) {
                    if (selectedRow?.index == cells[i + 1]?.containerWidget?.index) {
                      requirement += (getDescriptionFromRow(cells[i + 1]?.containerWidget) + "\n")
                      answer += (getTextFromCell(cells[i + 1]) + "\n")
                      no += getTextFromCell(cells[i + 1]?.containerWidget?.childWidgets[0])
                      i++;
                    } else {
                      break;
                    }
                  }
                  data.push({ 'requirement': requirement, 'answer': answer, 'no': no })
                }
              }
              setComplianceData(data)
              setCurrentSlide(3)
              // setComplianceModal(true);
              break;
          }
        };
      }
    }

  }, [previousOpenedDocument, selectedTableData, editorName])

  const handleSelectionChange = () => {
    let documentObject: any = $('#docx_container');
    if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
      let documentEditor: any = documentObject[0]?.ej2_instances[0]?.documentEditor
      const selection: any = documentEditor.editor.selection;
      setSelectedTableData({})
      setSelectedText('')
      if (selection.start?.paragraph.isInsideTable && selection.end.paragraph?.isInsideTable) {
        const selectedCells: any[] = selection.getSelectedCells();
        let selectedTables: any[] = [];
        let selectedRows: any[] = [];
        let selectedCellTexts: any[] = []
        if (selectedCells.length === 0) {
          selectedCells.push(selection.start.paragraph?.associatedCell);
        }

        selectedCells.forEach(cell => {
          const table = cell.ownerTable;
          if (!selectedTables.includes(table)) {
            selectedTables.push(table);
          }

          const row = cell.ownerRow;
          if (!selectedRows.includes(row)) {
            selectedRows.push(row);
          }
          selectedCellTexts.push(getTextFromCell(cell))
        });
        setSelectedTableData({
          "selectedTables": selectedTables,
          "selectedRows": selectedRows,
          "selectedCells": selectedCells,
          "selectedCellTexts": selectedCellTexts
        })
      } else {
        setSelectedText(selection.text)
      }
    }
  }

  const onContentChange = () => {
    setContentChanged(true)
  }

  const saveExcelFile = (blob: Blob) => {
    try {
      let file = new File([blob], openedDocument);
      let formData: FormData = new FormData();
      formData.append("email", user.sub.email);
      formData.append("file", file);
      formData.append("tender_id", tender_id);
      var req = new XMLHttpRequest();
      req.open(
        'POST',
        process.env.REACT_APP_API_URL + "api/autoSave",
        true
      );
      req.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("tender_auth"));
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          if (req.status === 200 || req.status === 304) {
            setUpdatedDocument(openedDocument)
            console.log('Saved sucessfully');
          }
        }
      };
      req.send(formData);
    } catch (error) {

    }
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (contentChanged) {
        let documentObject: any = $('#docx_container');
        if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
          try {
            documentObject[0]?.ej2_instances[0]?.documentEditor.saveAsBlob('Docx').then((blob: Blob) => {
              saveExcelFile(blob)
            }).catch((error) => {
              console.log(error);
            });
          } catch (error) {
            console.log(error)
          }
        }
        setContentChanged(false)
      }
    }, 2000)
    return () => clearInterval(intervalId);
  }, [contentChanged]);

  const onToolbarClick = (args: ClickEventArgs) => {
    let documentObject: any = $('#docx_container');
    switch (args.item.id) {
      case "export-pdf":
        if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
          let pdfdocument: PdfDocument = new PdfDocument();
          let count: number = documentObject[0]?.ej2_instances[0]?.documentEditor.pageCount;
          documentObject[0].ej2_instances[0].documentEditor.documentEditorSettings.printDevicePixelRatio = 1.5;
          let loadedPage = 0;
          for (let i = 1; i <= count; i++) {
            setTimeout(() => {
              let format: ImageFormat = 'image/jpeg' as ImageFormat;
              // Getting pages as image
              let image = documentObject[0]?.ej2_instances[0]?.documentEditor.exportAsImage(i, format);
              image.onload = function () {
                let imageHeight = parseInt(
                  image.style.height.toString().replace('px', '')
                );
                let imageWidth = parseInt(
                  image.style.width.toString().replace('px', '')
                );
                let section: PdfSection = pdfdocument.sections.add() as PdfSection;
                let settings: PdfPageSettings = new PdfPageSettings(0);
                if (imageWidth > imageHeight) {
                  settings.orientation = PdfPageOrientation.Landscape;
                }
                settings.size = new SizeF(imageWidth, imageHeight);
                (section as PdfSection).setPageSettings(settings);
                let page = section.pages.add();
                let graphics = page.graphics;
                let imageStr = image.src.replace('data:image/jpeg;base64,', '');
                let pdfImage: any = new PdfBitmap(imageStr);
                graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
                loadedPage++;
                if (loadedPage == count) {
                  // Exporting the document as pdf
                  pdfdocument.save(
                    (openedDocument === ''
                      ? 'download'
                      : openedDocument.slice(0, openedDocument.lastIndexOf('.'))) + '.pdf'
                  );
                }
              };
            }, 500);
          }
        }
        break;
      case "export-docx":
        if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
          documentObject[0]?.ej2_instances[0]?.documentEditor.save(openedDocument === ''
            ? 'download'
            : openedDocument.slice(0, openedDocument.lastIndexOf('.')), 'Docx');
        }
        break;
      default:
        break;
    }
  }

  const onContextMenuBeforeOpen = (args) => {
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet && args.element.id === spreadsheet.element.id + '_contextmenu') {
      spreadsheet.addContextMenuItems([{
        text: 'Run Automagic',
        id: 'auto_fill_document',
        iconCss: 'e-icons e-de-ctnr-tableofcontent',
      }], 'Cut', false);
    }
  };

  const onContextMenuItemSelect = (args) => {
    const selectedItemId = args.item.id;
    let spreadsheet: any = spreadsheetRef.current;
    const activeSheet = spreadsheet.getActiveSheet();

    if (selectedItemId === 'auto_fill_document' && spreadsheet) {
      // Perform action for "Run Automagic" click
      console.log('Run Automagic clicked');
      const selectedRange = spreadsheet.sheets[spreadsheet.activeSheetIndex].selectedRange;
      // Get the actual cell data for the selected range
      const documentsForCompanyKnowledgebade: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document)
      const documentsForWorkspace: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_1").map(item => item.document)


      const fetchAndHandleResponse = async (params: any, key: any) => {
        console.log("here")
        const response: any = await fetch(`${process.env.REACT_APP_API_URL}api/getAnswer`, {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
            'Content-Type': 'application/json',
          }
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let context = ''
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          const regex = /([^:]*)json_data:(\{.*?\})/g;
          const match = regex.exec(chunkValue);

          if (match && match.length >= 3) {
            const beforeText = match[1];
            if (beforeText) {
              context += beforeText
            }
          } else if (!chunkValue.includes("</")) {
            context += chunkValue
          }
        }
        console.log(context)
        spreadsheet.updateCell({ value: context }, key);
      };

      const generateQuestion = (tableHeaderRequirement: string, description: string, selectedCellText: string, language: string) => {
        const baseQuestion = language === 'no'
          ? `Du er en smart AI-assistent designet for å fylle ut tekst automatisk. Tabelloverskriften er '${tableHeaderRequirement}'. Vi trenger å fylle ut tekst for denne tabellcellen basert på dette kravet: '${description}'.`
          : `You are a smart AI Assistant designed to provide text for autofill. The table header is '${tableHeaderRequirement}'. We need to provide text to autofill for that table cell based on this requirement: '${description}'.`;

        return `${baseQuestion} ${tableHeaderRequirement === "Yes" || tableHeaderRequirement === "No"
          ? "If table header is a boolean value, reply only with a boolean value."
          : `Here is more description to answer: ${selectedCellText}`
          }`;
      };

      const handleAutofill = async () => {
        const language = i18n.language || "en";
        const baseParams = {
          email: user.sub.email,
          companyId: user.sub.companyId,
          tender_id: tender_id,
          otender_id: tender_id,
          selectedDocumentList: documentsForWorkspace,
          companyKnowledgeBaseId: companyKnowledgeBaseId,
          documentsForCompanyKnowledgebade: documentsForCompanyKnowledgebade,
          autofill: true
        };

        // Get number of columns


        spreadsheet.getData(selectedRange).then(async (selectedData) => {
          console.log('Selected range:', selectedRange);

          const totalColumns = activeSheet.usedRange.colIndex;

          for (const [key, value] of selectedData.entries()) {
            const cellLetter = key.match(/[A-Z]+/)[0];  // Extract column letter
            const cellRow = key.match(/\d+/)[0];        // Extract row number
            const cellAKey = `A${cellRow}`;  // e.g., A5
            const cellText = await getExcelCellText(spreadsheet, cellAKey);
            const tableHeaderRequirement = await getExcelCellText(spreadsheet, `${cellLetter}1`);
            const description = await getDescriptionFromExcelRow(spreadsheet, cellRow, totalColumns);
            const selectedCellText = value?.value ? value?.value : '';

            console.log('Total Columns:', totalColumns);
            console.log('tableHeaderRequirement:', tableHeaderRequirement);
            console.log('Cell', cellAKey, cellText);
            console.log(`Cell: ${key}`);
            console.log('selectedCellText:', selectedCellText);
            console.log(cellLetter);
            console.log('getDescriptionFromExcelRow', description);

            const question = generateQuestion(tableHeaderRequirement, description, selectedCellText, language);
            const params = { ...baseParams, question };

            // Update the cell value to an empty string
            spreadsheet.updateCell({ value: '' }, key);

            // Fetch and handle response after this loop iteration completes
            await fetchAndHandleResponse(params, key);
          }

          // Perform your custom auto-fill functionality here using selectedData
        });



      };

      handleAutofill();

    }
  };

  return (
    <div className='bg-main_bg'>
      <div className='flex items-center font-medium text-black text-xl text-left ml-2 mt-[5px]'>
        <img src={process.env.PUBLIC_URL + '/img/Automagic-page3.png'} width={50} height={50} className='mr-[10px]'>
        </img>
        <div>
          <div className='flex'>
            <div className="text-xl font-bold text-left text-black">{t('Agent Automagic')}</div>
          </div>
          <div className='text-[15px]'>
            {t("Agent_Automagic_Description")}
          </div>
        </div>
      </div>
      {
        !isWebsite(openedDocument) ?
          editorName === "pdf" ? (
            <PdfViewerComponent
              id="pdf_container"
              serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
              style={{ height: "calc(100vh - 142px)" }}
              downloadFileName={openedDocument}
            >
              <Inject services={[PDFToolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView, ThumbnailView,
                Print, TextSelection, TextSearch, FormFields, FormDesigner]} />
            </PdfViewerComponent>
          ) : editorName == "xlsx" ? (
            <SpreadsheetComponent ref={spreadsheetRef} openUrl='https://services.syncfusion.com/react/production/api/spreadsheet/open' saveUrl='https://services.syncfusion.com/react/production/api/spreadsheet/save' actionComplete={(args) => {
              spreadsheetRef.current?.save()
            }} beforeSave={(args) => {
              args.needBlobData = true; //To trigger saveComplete event
              args.isFullPost = false; // To get blob data of Spreadsheet
            }} saveComplete={(args) => {
              saveExcelFile(args.blobData)
            }}
              contextMenuBeforeOpen={onContextMenuBeforeOpen}
              contextMenuItemSelect={onContextMenuItemSelect}
            />
          ) : editorName == "docx" ?
            <DocumentEditorContainerComponent ref={scope => { documenteditor = scope; }} id="docx_container" serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/" enableToolbar={true} height={'calc(100vh - 142px)'} showPropertiesPane={false} contentChange={() => onContentChange()} created={() => onCreated()} toolbarItems={items} toolbarClick={onToolbarClick}
            />
            :
            <div className="text-black space-y-4 text-left pt-[16%] pl-[20%]" style={{ height: "calc(100vh - 142px)" }}>
              <div className="font-bold text-[25px]">{t('Editor_Guide')}</div>
              <div className="text-[20px] leading-relaxed">
                {t('Editor_Sentence_1')}
              </div>
              <div className="text-[20px] leading-relaxed pl-[15px]">
                {t('Editor_Sentence_2')}
              </div>
              <div className="text-[20px] leading-relaxed pl-[15px]">
                {t('Editor_Sentence_3')}
              </div>
            </div> : <></>
      }
    </div>
  );
}