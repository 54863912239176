import React, { FC, useState, useEffect, } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { Navbar } from "../Navbar";
import { getDateInfo, isWebsite, parseJWT, getColorByScore } from "../../utils";
import { LeftComponent } from "./LeftComponent";
import { RightComponent } from "./RightComponent";
import { ChatComponent } from "./ChatComponent";
import Draggable from "react-draggable";
import { ViewDrawer } from "./ViewDrawer";
import "../home.css";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
// import StatusModal from "./RightComponent/StatusModal"
const { default: axios } = require('axios');
type SomeComponentProps = RouteComponentProps;
const Main: FC<SomeComponentProps> = ({ history }) => {
    const { t } = useTranslation();
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [openedDocumentURL, setOpenedDocumentURL] = useState("");
    const [openedDocument, setOpenedDocument] = useState("");
    const [previousOpenedDocument, setPreviousOpenedDocument] = useState<string>("")
    const [tender_tab, setTenderTab] = useState("");
    const [tender_title, setTenderTitle] = useState("")
    const [positionFirst, setPositionFirst] = useState({ positionFirstX: 0 });
    const [positionSecond, setPositionSecond] = useState({ positionSecondX: 0 });
    const [documentUpdated, setDocumentUpdated] = useState(false);
    const [isChatWindowClosed, setChatWindowClosed] = useState(true);
    const [selectedDocumentList, setSelectedDocumentList] = useState<any[]>([]);
    const [isFolderContainFulldocuments, setFolderContainFulldocuments] = useState<boolean>(false);
    const [companyKnowledgeBaseId, setCompanyKnowledgeBaseId] = useState<any>('all_folders')
    const [documentAction, setDocumentAction] = useState<string>("no-deleted");
    const [promptList, setPromptList] = useState<any[]>([]);
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<any>(null)
    const [selectedFolderID, setSelectedFolderID] = useState<any>("all_folders");
    const [selectedFolderName, setSelectedFolderName] = useState<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isSettingFinished, setSettingFinished] = useState(false)
    const [isLoading, setLoadingStatue] = useState(false)
    const [opportunities, setOpportunitiesList] = useState<any[]>([])
    const [tabName, setTabName] = useState<any>("tab_2")
    const [dateType, setDateType] = useState("day")
    const [currentDate, setCurrentDate] = useState<Date | any>(new Date());
    const [tenderList, setTenderList] = useState<any[]>([]);
    const savedFolderStatus = localStorage.getItem('folderStatus');
    const [openedStatus, setOpenedStatus] = useState<any[]>(savedFolderStatus ? JSON.parse(savedFolderStatus) : [
        { openedIndex: 0, isOpened: true, tabName: 'tab_1' },
        { openedIndex: 0, isOpened: true, tabName: 'tab_2' },
    ])

    const [cpvCodes, setCPVCodes] = useState<any[]>([]);
    useEffect(() => {
        cpvCodesFetch()
    }, [])
    useEffect(() => {
        if (isSettingFinished == true) {
            setTimeout(async () => {
                await cpvCodesFetch()
                setSettingFinished(false)
            }, 1);
        }
    }, [isSettingFinished])

    const getDateType = async (selectedDate: any) => {
        try {
            const data = new FormData();
            data.append("email", user.sub.email);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getDateType", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (selectedDate.year + "/" + selectedDate.month + "/" + selectedDate.day == result.data.date) {
                setDateType("week")
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const isInputElement = event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement ||
                event.target instanceof HTMLSelectElement;

            if (!isInputElement) {
                if (event.key === 'ArrowLeft') {
                    setTimeout(() => {
                        if (currentSlide === 0) {
                            setCurrentSlide(3)
                        } else {
                            const slide_number = currentSlide - 1;
                            setCurrentSlide(slide_number);
                        }
                    }, 200);
                } else if (event.key === 'ArrowRight') {
                    setTimeout(() => {
                        if (currentSlide === 3) {
                            setCurrentSlide(0)
                        } else {
                            const slide_number = currentSlide + 1;
                            setCurrentSlide(slide_number)
                        }
                    }, 200);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentSlide]);


    useEffect(() => {
        opportuniteisDataFetch(currentDate);
    }, [cpvCodes])

    const cpvCodesFetch = async () => {
        try {
            const data = new FormData();
            data.append("companyId", user.sub.companyId);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getSettingCPVCodes", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setCPVCodes(result?.data?.cpvCodes)
        } catch (error) {
            console.log(error)
        }
    }
    const promptDataFetch = async () => {
        try {
            const data = new FormData();
            data.append("companyId", user.sub.companyId);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getPrompts", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setPromptList(result.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    const opportuniteisDataFetch = async (date: Date) => {
        try {
            const date_json = getDateInfo(date);
            getDateType(date_json)
            const data = new FormData();
            const currentDate = new Date()
            if (dateType == "week" && currentDate.getDate() == date.getDate()) {
                data.append("is7DaysData", "true");
            }
            data.append("email", user.sub.email);
            data.append("companyId", user.sub.companyId);
            data.append("year", date_json.year);
            data.append("month", date_json.month);
            data.append("day", date_json.day);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getOpportunities", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            const jsonData = result?.data?.data
            //Here, we implement sort engine
            if (jsonData?.length > 0) {
                const sortedData = jsonData.map(obj => {
                    // Calculate the color for each object using getColorByScore
                    const color = getColorByScore(parseInt(obj?.relevanceScore), obj?.cpvCodes ? obj?.cpvCodes : [], cpvCodes ? cpvCodes : []);
                    // Add the color property to the object
                    return { ...obj, color };
                }).sort((a, b) => {
                    // Sort by color priority: Green, Yellow, Red
                    const colorOrder = ['bg-[#43A440]', 'bg-[#FFC107]', 'bg-[#E3343A]'];

                    // Compare colors first
                    const colorDifference = colorOrder.indexOf(a.color) - colorOrder.indexOf(b.color);
                    if (colorDifference !== 0) {
                        return colorDifference; // Sort by color order
                    }

                    // If the colors are the same, sort by relevanceScore in descending order
                    return b.relevanceScore - a.relevanceScore; // High score comes first
                });

                setOpportunitiesList(sortedData)
            } else {
                setOpportunitiesList([])
            }

        } catch (error) {
            setOpportunitiesList([])
            console.log(error)
        }
    }

    const summaryDataFetch = async () => {
        try {
            const data = new FormData();
            data.append("companyId", user.sub.companyId);
            data.append('tender_id', selectedFolderID);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getSummaries", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setSummaryList(result.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        promptDataFetch();
    }, [])

    useEffect(() => {
        if (currentSlide == 1)
            summaryDataFetch();
    }, [selectedFolderID, currentSlide])

    const onDragFirst = (e: any, data: any) => {
        setPositionFirst({ positionFirstX: data.x });
    };
    const onDragSecond = (e: any, data: any) => {
        setPositionSecond({ positionSecondX: data.x });
    };

    const styleLeftSide = {
        width: window.innerWidth < 760 ? `100%` : `calc(19.666%  + ${positionSecond.positionSecondX + positionFirst.positionFirstX + "px"})`,
        height: "calc(100vh - 72px)"
    }

    const styleCenter = {
        width: window.innerWidth < 760 ? `100%` : isChatWindowClosed ? `80.33%` : `50%`,
        height: "calc(100vh - 72px)"
    }

    const styleRightSide = {
        width: window.innerWidth < 760 ? `100%` : isChatWindowClosed ? `0%` : `calc(33.334%  - ${positionFirst.positionFirstX + positionSecond.positionSecondX + "px"})`,
        height: "calc(100vh - 72px)",
        minWidth: isChatWindowClosed ? `0px` : `28%`,
        overflow: `hidden`
    }
    const isImage = (text: string) => {
        let fileName = text.toLocaleLowerCase()
        if (fileName.indexOf("jpg") > 0 || fileName.indexOf("png") > 0 || fileName.indexOf("gif") > 0) {
            return true;
        }
        else return false;
    }
    const handleDocumentClick = async (document: any, action_type: string, folder: string, companyBaseTenderID: string) => {
        var url = process.env.REACT_APP_API_URL + "upload/" + user.sub.companyName + "/" + selectedFolderID + "/" + document
        if (typeof document === 'string') {
            console.log('The value is a string:', document);
            if (folder == "company") {
                url = process.env.REACT_APP_API_URL + "upload/" + user.sub.companyName + "/" + companyBaseTenderID + "/" + document
            }
        } else if (typeof document === 'object' && document !== null) {
            let tender_id = document?.tender_id ? document?.tender_id : selectedFolderID
            document = document?.document
            url = process.env.REACT_APP_API_URL + "upload/" + user.sub.companyName + "/" + tender_id + "/" + document
        }

        isWebsite(document) ? window.open(document, '_blank') : null;

        isImage(document) ? window.open(url, '_blank') : null;
        if (selectedTab != "tab_2")
            setPreviousOpenedDocument(document)
        setOpenedDocument(document)
        setOpenedDocumentURL(url);
        setDocumentAction(action_type)
        if (document && !isWebsite(document) && !isImage(document))
            setCurrentSlide(2)
    }

    const handleMetadata = (tab: string, folder: string) => {
        setTenderTab(tab)
        setTenderTitle(folder)
    }

    const handleSelectedDocuments = (selecteDocuments: any[], documents: any[], isFolderContainFulldocuments: boolean) => {
        if (selecteDocuments.length == 0) {
            if (selectedDocumentList.length == 0) {
                setSelectedDocumentList(documents)
            } else {
                let docs: any[] = selectedDocumentList.filter(item => item?.tabName != selectedTab)
                let mergedArray: any[] = [...docs, ...documents]
                setSelectedDocumentList(mergedArray)
            }
        } else {
            let docsTab1: any[] = selecteDocuments.filter(item => item?.tabName == "tab_1")
            let docsTab2: any[] = selecteDocuments.filter(item => item?.tabName == "tab_2")
            if (docsTab1.length > 0) {
                let docs: any[] = documents.filter(item => item?.tabName != "tab_1")
                if (docs?.length == 0) {
                    let selectedDocs: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2")
                    let mergedArray: any[] = [...docsTab1, ...selectedDocs]
                    setSelectedDocumentList(mergedArray)
                } else {
                    let mergedArray: any[] = [...docsTab1, ...docs]
                    setSelectedDocumentList(mergedArray)
                }
            }
            if (docsTab2.length > 0) {
                let docs: any[] = documents.filter(item => item?.tabName != "tab_2")
                if (docs?.length == 0) {
                    let selectedDocs: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_1")
                    let mergedArray: any[] = [...docsTab2, ...selectedDocs]
                    setSelectedDocumentList(mergedArray)
                } else {
                    if (docsTab1.length == 0) {
                        let mergedArray: any[] = [...docsTab2, ...docs]
                        setSelectedDocumentList(mergedArray)
                    }
                }
            }
        }
        setFolderContainFulldocuments(isFolderContainFulldocuments)
    }

    const handleSelectedFolder = (folderID: string, folderName: string) => {
        setSelectedFolderID(folderID ? folderID : "all_folders");
        setSelectedFolderName(folderName)
    }

    const handleSelectedTab = (tab: string) => {
        setSelectedTab(tab)
    }

    useEffect(() => {
        if (selectedTab == "tab_2") {
            let selectedList: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2")
            if (isFolderContainFulldocuments) {
                setCompanyKnowledgeBaseId(selectedFolderID)
                if (selectedFolderName == null) {
                    setCompanyKnowledgeBaseId('all_folders')
                }
            } else {
                if (selectedList.length == 1) {
                    console.log(selectedList[0]?.document)
                } else if (selectedList.length > 1) {
                    console.log(selectedList.map(item => item.document).join(","))
                } else {
                    setCompanyKnowledgeBaseId('all_folders')
                }
            }
        }
    }, [selectedTab, openedDocument, selectedDocumentList, previousOpenedDocument, selectedFolderName, selectedFolderID, isFolderContainFulldocuments])

    return (
        <div className="leading-normal tracking-normal text-indigo-400 bg-cover bg-fixed bg-[url('../public/img/bg.png')]">
            <Navbar selectedTab={selectedTab} openedDocument={openedDocument} selectedDocumentList={selectedDocumentList} previousOpenedDocument={previousOpenedDocument} selectedFolderName={selectedFolderName} isFolderContainFulldocuments={isFolderContainFulldocuments} opportuniteisDataFetch={opportuniteisDataFetch} promptDataFetch={promptDataFetch} setSettingFinished={setSettingFinished}></Navbar>
            <div className="flex flex-col md:flex-row bg-bg_body">
                <LeftComponent selectedTab={selectedTab} tabName={tabName} setTabName={setTabName} handleDocumentClick={handleDocumentClick} styleLeftSide={styleLeftSide} openedDocument={openedDocument} handleMetadata={handleMetadata} handleSelectedDocuments={handleSelectedDocuments} summaryDataFetch={summaryDataFetch} handleSelectedFolder={handleSelectedFolder} setTenderList={setTenderList} tenderList={tenderList} handleSelectedTab={handleSelectedTab} setPreviousOpenedDocument={setPreviousOpenedDocument} setOpenedStatus={setOpenedStatus} openedStatus={openedStatus} setCurrentSlide={setCurrentSlide} docList={selectedDocumentList} promptDataFetch={promptDataFetch}></LeftComponent>
                <Draggable
                    defaultPosition={{ x: 0, y: 0 }}
                    defaultClassName="no_trasform"
                    position={{ x: positionFirst.positionFirstX, y: 0 }}
                    onDrag={onDragFirst}
                >
                    <div className="z-[1]">
                        <ViewDrawer cssDrawer="drawerFirst" cssHandle="handleFirst" />
                    </div>
                </Draggable>
                <RightComponent cpvCodesFetch={cpvCodesFetch} currentDate={currentDate} setCurrentDate={setCurrentDate} dateType={dateType} setDateType={setDateType} setSummaryList={setSummaryList} setTabName={setTabName} handleSelectedTab={handleSelectedTab} document={openedDocumentURL} openedDocument={openedDocument} documentUpdated={documentUpdated} styleCenter={styleCenter} documentAction={documentAction} setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)} promptList={promptList} tender_tab={tender_tab} tender_title={tender_title} selectedDocumentList={selectedDocumentList} promptDataFetch={promptDataFetch} summaries={summaryList} handleDocumentClick={handleDocumentClick} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedFolderID={selectedFolderID} previousOpenedDocument={previousOpenedDocument} opportunities={opportunities} isChatWindowClosed={isChatWindowClosed} opportuniteisDataFetch={opportuniteisDataFetch} tenderList={tenderList} setTenderList={setTenderList} selectedTab={selectedTab} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} cpvCodes={cpvCodes} summaryDataFetch={summaryDataFetch} setSettingFinished={setSettingFinished} isSettingFinished={isSettingFinished} setLoadingStatue={setLoadingStatue} tender_id={selectedFolderID} companyKnowledgeBaseId={companyKnowledgeBaseId}></RightComponent>
                {
                    !isChatWindowClosed ?
                        <Draggable
                            defaultPosition={{ x: 0, y: 0 }}
                            defaultClassName="no_trasform"
                            position={{ x: positionSecond.positionSecondX, y: 0 }}
                            onDrag={onDragSecond}
                        >
                            <div>
                                <ViewDrawer cssDrawer="drawerSecond" cssHandle="handleSecond" />
                            </div>
                        </Draggable>
                        : <></>
                }
                <ChatComponent documentClick={handleDocumentClick} styleRightSide={styleRightSide} openedDocument={openedDocument} setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)} isChatWindowClosed={isChatWindowClosed} setChatWindowClosed={(val: boolean) => setChatWindowClosed(val)} promptDataFetch={promptDataFetch} selectedDocumentList={selectedDocumentList} selectedTab={selectedTab} previousOpenedDocument={previousOpenedDocument} selectedFolderName={selectedFolderName} isFolderContainFulldocuments={isFolderContainFulldocuments} selectedFolderID={selectedFolderID} companyKnowledgeBaseId={companyKnowledgeBaseId}></ChatComponent>
            </div>
        </div>
    );
};

export default Main;
