import React, { useRef, useEffect, useState } from 'react'
import "../../../App.css"
import { useTranslation } from 'react-i18next';
import { getColorByScore, getDateLabel, getRemainingDaysAndHours, isWebsite } from '../../../utils';
import { toast } from "react-toastify";
import { parseJWT } from '../../../utils';
const { default: axios } = require('axios');
import { ReactComponent as DotsIcon } from "../../../assets/svg/threedots.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as NormalHeart } from "../../../assets/svg/normal.svg";
import { ReactComponent as HoverHeart } from "../../../assets/svg/hover.svg";
import { ReactComponent as ClickedHeart } from "../../../assets/svg/clicked.svg";
import i18n from '../../../i18n';
import { io } from 'socket.io-client';
import { SelectTool, setElementStype } from '@syncfusion/ej2-react-pdfviewer';
import { ReactComponent as Icon1 } from "../../../assets/svg/uncompleted.svg";
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/complete.svg";
interface OpportunitiesProps {
    setCurrentSlide: any
    opportunities: any[]
    isChatWindowClosed: boolean
    opportuniteisDataFetch: any
    selectedFolderID: any
    setTenderList: any
    selectedTab: string
    setOpenedStatus: any
    handleSelectedFolder: any
    cpvCodes: any[]
    setIsFromModal: any
    promptDataFetch: any
    summaryDataFetch: any
    isSettingFinished: any
    setSettingFinished: any
    setLoadingStatue: any
    tenderList: any
    handleSelectedTab: any
    setAddedState: any
    setDocumentUrl: any
    setDocumentsCount: any
    setCurrentDate: any
    currentDate: any
    setClickedHeartIndex: any
    clickedHeartIndex: any
    setHover: any
    hoverHeartIndex: any
    dateType: any
    setDateType: any
    cpvCodesFetch: any
    setSocketId: any
}
const socketId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const Socket = io(`${process.env.REACT_APP_API_URL}`, {
    query: { socketId: socketId }
});

const GlobalSocket = io(`${process.env.REACT_APP_API_URL}`);
export const Opportunities: React.FC<OpportunitiesProps> = ({ setHover, hoverHeartIndex, setClickedHeartIndex, clickedHeartIndex, setCurrentDate, currentDate, setDocumentUrl, setDocumentsCount, setAddedState, setCurrentSlide, opportunities, isChatWindowClosed, opportuniteisDataFetch, selectedFolderID, setTenderList, selectedTab, setOpenedStatus, handleSelectedFolder, cpvCodes, setIsFromModal, promptDataFetch, summaryDataFetch, isSettingFinished, setSettingFinished, setLoadingStatue, tenderList, handleSelectedTab, dateType, setDateType, cpvCodesFetch, setSocketId }) => {
    const { t } = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipIndex, setTooltipIndex] = useState<any>(null);
    const [isRefreshing, setRefreshing] = useState(false)
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [isDownlaoding, setDownloading] = useState(false);
    const [overIndex, setOverIndex] = useState(-1);
    const [currentStep, setCurrentStep] = useState(1);
    const [toastId, setToastId] = useState<any>(null);
    const [stackState, setStackState] = useState<any>(false);
    const [addToWorkSpaceStack, setAddToWorkSpaceStack] = useState<any>([]);
    const [email, setEmail] = useState<any>(null);
    const [pageCount, setPageCount] = useState<any>(1);
    const [tender_id, setTenderId] = useState<any>(null);
    const [unique_id, setUniqueID] = useState<any>(null);
    const topRef = useRef<HTMLDivElement | null>(null);
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const getOpportunityFromID = async (tender_id) => {
        const formData: any = new FormData();
        formData.append('email', user.sub.email)
        formData.append('tender_id', tender_id)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getOpportunityFromID", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200 && result.data.opportunity) {
            setCurrentDate(new Date(result.data.opportunity.date))
            opportuniteisDataFetch(new Date(result.data.opportunity.date))
            if (result.data.opportunity?.isAddedToWorkSpace) {
                toast.warning(t("Already Added to WorkSpace!"), {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setEmail(null)
                setTenderId(null)
                localStorage.removeItem("email")
                localStorage.removeItem("tender_id")
            } else {
                setTimeout(() => {
                    addToWorkSpace(result.data.opportunity, result.data.opportunity.id, 'tab_1')
                }, 1000);
            }
        }
    }
    const refs = useRef<(HTMLDivElement | null)[]>(Array(opportunities.length).fill(null));
    const toggleDropdown = (index, event) => {
        const currentRef = refs?.current[index]
        if (currentRef &&
            !currentRef.contains(event.target as Node)) {
            // Clicked outside all dropdowns, handle the close action here
            setActiveDropdownIndex(null);
        } else {
            setActiveDropdownIndex(index === activeDropdownIndex ? null : index);
        }
    };

    useEffect(() => {
        GlobalSocket.on('tenderListChanged', async (message) => {
            if (user?.sub?.email === message?.From) {
                await opportuniteisDataFetch(currentDate)
            }
        });
        return () => {
            GlobalSocket.off('tenderListChanged');
        };
    }, []);
    const addNewTender = async (opportunity: any) => {
        Socket.on('filesDownloaded', (message: { data: [] }) => {
            console.log(message.data)
            let newIndex = message.data.length
            setTimeout(setTenderList(message.data), 0)
            setOpenedStatus((prevTabs) => {
                return prevTabs.map((tab) => {
                    if (tab.tabName === selectedTab) {
                        return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                    } else {
                        return tab;
                    }
                });
            });
        });
        let data = new FormData();
        data.append("id", opportunity.id);
        data.append("email", user.sub.email);
        data.append("title", opportunity.tenderText);
        data.append("document", opportunity.tenderURL);
        data.append("customerName", opportunity.customerName);
        data.append("competitionDocsUrl", opportunity?.competitionDocsUrl);
        data.append("date", opportunity.tenderDate)
        data.append("est", opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : t("N/A"));
        data.append("text", "TenderURL: " + opportunity.tenderURL + "\n" + "TenderText:" + opportunity.tenderText + "\n" + "TenderType:" + opportunity.tenderType + "\n" + "CustomerName:" + opportunity.customerName + "\n" + "TenderDate:" + opportunity.tenderDate + "\n" + "Tender ShortDescription:" + opportunity.shortDescription + "\n");
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createEmptyWorkSpace", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {

        }
        const formData = new FormData();
        formData.append("companyId", user.sub.companyId);
        formData.append("tab", selectedTab);
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        setTenderList(result.data.data);
        var newIndex = result.data.data.length;
        setOpenedStatus((prevTabs) => {
            return prevTabs.map((tab) => {
                return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
            });
        });
        handleSelectedFolder(result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.id, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
        if (user?.sub?.membership == "trial") {
            toast.success((newIndex) + " of 3 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else if (user?.sub?.membership == "basic") {
            toast.success((newIndex) + " of 12 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else if (user?.sub?.membership == "basic extended") {
            toast.success((newIndex) + " of 24 " + t("allowed Workspaces created!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
        else {
            toast.success(t("Added to WorkSpace!"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
    }

    useEffect(() => {
        if (isRefreshing) {
            toast.success(t("Loading tenders"), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
    }, [isRefreshing])
    const refreshOpportunities = async () => {
        try {
            if (isSettingFinished == true) {
                const formData: any = new FormData();
                formData.append('email', user.sub.email)
                formData.append("companyId", user.sub.companyId);
                const countResult = await axios.post(process.env.REACT_APP_API_URL + "api/getOpportunitiesCount", formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                setRefreshing(true)
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1;
                const day = currentDate.getDate();
                const data: any = new FormData();
                data.append('email', user.sub.email)
                data.append('companyId', user.sub.companyId)
                data.append('year', year)
                data.append('month', month)
                data.append('day', day)
                if (countResult.status === 200) {
                    if (countResult.data.count == 0) {
                        data.append('twoWeeksData', "true")
                        setDateType("week")
                    }
                    else {
                        setDateType("day")
                    }
                    const result = await axios.post(process.env.REACT_APP_API_URL + "api/refreshOpportunities", data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    });
                    if (result.status === 200) {
                        await cpvCodesFetch()
                        await opportuniteisDataFetch(currentDate)
                    }
                    setRefreshing(false)
                    setSettingFinished(false)
                }
            }
        } catch (error) {
            setRefreshing(false)
            setSettingFinished(false)
        }
    }
    useEffect(() => {
        refreshOpportunities()
    }, [isSettingFinished]);
    const downloadStack = async () => {
        if (isDownlaoding == false) {
            if (addToWorkSpaceStack.length > 0) {
                let value = addToWorkSpaceStack[0];
                await addToWorkSpace(value.opportunity, value.index, value.tab)
                const updatedItems = addToWorkSpaceStack.slice(1);
                setAddToWorkSpaceStack(updatedItems);
            } else {
                if (stackState == true) {
                    // setAddedState(true);
                    setStackState(false)
                }
            }
        }
    }
    useEffect(() => {
        downloadStack()
    }, [addToWorkSpaceStack, isDownlaoding]);
    const addToWorkSpace = async (opportunity: any, index: any, tab: any) => {
        setSocketId(socketId)
        if ((tenderList.length >= 3 && user?.sub?.membership == "trial") || (tenderList.length >= 12 && user?.sub?.membership == "basic") || (tenderList.length >= 24 && user?.sub?.membership == "basic extended")) {
            toast.error(t("Please upgrade your license to use this functionality"), {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
            })
            return false;
        }
        let heartIndexs = [...clickedHeartIndex, index]
        setClickedHeartIndex(heartIndexs);
        setStackState(true)
        if (isDownlaoding == true) {
            console.log("Already downloading")
            let stackValue = { "opportunity": opportunity, "index": index, "tab": tab }
            let stack = [...addToWorkSpaceStack, stackValue]
            console.log("stact", stack)
            setAddToWorkSpaceStack(stack)
            console.log(stack)
            return false;
        }
        setDownloading(true)
        addNewTender(opportunity)
        setTimeout(async () => {
            if (toastId) {
                toast.dismiss(toastId);
            }
            const id = toast(
                <CustomToast currentStep={1} />,
                {
                    autoClose: false, // Prevent auto-close to control it manually
                    closeOnClick: true,
                    draggable: false,
                    hideProgressBar: true,
                    pauseOnHover: true,
                    progress: 0
                }
            );
            setToastId(id);
            Socket.on('status_update', (message: { status: number }) => {
                setTimeout(() => {
                    console.log("message.status", message.status)
                    toast.update(id, {
                        render: <CustomToast currentStep={message.status} />
                    });
                }, 100)
                if (message.status == 6) {
                    setTimeout(() => {
                        toast.dismiss(id);
                    }, 2000)
                }
            });
            let data = new FormData();
            data.append("id", opportunity.id);
            data.append("email", user.sub.email);
            data.append("companyId", user.sub.companyId);
            data.append("tab", tab ? tab : selectedTab);
            data.append("title", opportunity.tenderText);
            data.append("document", opportunity.tenderURL);
            data.append("customerName", opportunity.customerName);
            data.append("est", opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : t("N/A"));
            data.append("competitionDocsUrl", opportunity?.competitionDocsUrl);
            data.append("date", opportunity.tenderDate)
            data.append("socketId", socketId)
            data.append("text", "TenderURL: " + opportunity.tenderURL + "\n" + "TenderText:" + opportunity.tenderText + "\n" + "TenderType:" + opportunity.tenderType + "\n" + "CustomerName:" + opportunity.customerName + "\n" + "TenderDate:" + opportunity.tenderDate + "\n" + "Tender ShortDescription:" + opportunity.shortDescription + "\n");
            try {
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createFolderByTender", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    if (result.data.isexist == "true") {
                        toast.dismiss(id);
                        toast.warning(t("Already Added to WorkSpace!"), {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: 0,
                            toastId: "my_toast",
                        });
                        return false;
                    }
                    let newIndex = result.data.data.filter(item => item.isFolderDeleted === 0).length
                    setTenderList(result.data.data)
                    setOpenedStatus((prevTabs) => {
                        return prevTabs.map((tab) => {
                            if (tab.tabName === selectedTab) {
                                return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                            } else {
                                return tab;
                            }
                        });
                    });
                    handleSelectedFolder(result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.id, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
                    summaryDataFetch()
                    setDocumentsCount(result.data.documents.length);
                    setDocumentUrl(result.data.documentUrl)
                    setCurrentStep(1)
                    promptDataFetch()
                    setCurrentSlide(1)
                    if (result.data.documents.length > 0) {
                        setIsFromModal(true)
                    } else {
                        toast.dismiss(id);
                    }
                    setTimeout(() => {
                        setDownloading(false)
                    }, 2000)
                    setEmail(null)
                    setTenderId(null)
                    localStorage.removeItem("email")
                    localStorage.removeItem("tender_id")
                } else {
                    summaryDataFetch()
                    setDocumentsCount(0);
                    setDocumentUrl(null);
                    setCurrentStep(1)
                    promptDataFetch()
                    setTimeout(() => {
                        setDownloading(false)
                        toast.dismiss(id);
                    }, 2000)
                    setEmail(null)
                    setTenderId(null)
                    localStorage.removeItem("email")
                    localStorage.removeItem("tender_id")
                }
            } catch (error) {
                summaryDataFetch()
                setDocumentsCount(0);
                setDocumentUrl(null);
                setCurrentStep(1)
                promptDataFetch()
                setTimeout(() => {
                    setDownloading(false)
                    toast.dismiss(id);
                }, 2000)
                setEmail(null)
                setTenderId(null)
                localStorage.removeItem("email")
                localStorage.removeItem("tender_id")
            }
        }, 2000);

    }
    useEffect(() => {
        if (email == user.sub.email && selectedTab == 'tab_1') {
            setTimeout(() => {
                let opportunity = getOpportunityFromID(tender_id)
            }, 1000);
        }
    }, [email, tender_id, selectedTab])
    useEffect(() => {
        setEmail(localStorage.getItem("email"))
        setTenderId(localStorage.getItem("tender_id"))
    }, [])

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollTo(0, 0);
        }
    };
    return (
        <div className={`safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]`} ref={topRef}>
            <div className="flex items-center mt-2">
                <div className="flex items-center ml-2 text-xl font-medium text-left text-black">
                    <img src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} width={50} height={50} className='mr-[10px]'>
                    </img>
                    <div>
                        <div className='flex'>
                            <span className='font-bold'>
                                {t("Agent Match Maker")}
                            </span>
                            <div className='flex items-center'>
                                <svg className="mt-[4px] h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={async () => {
                                    if (currentDate) {
                                        const previousDate = new Date(currentDate);
                                        previousDate.setDate(currentDate.getDate() - 1);
                                        if (dateType == "week") {
                                            setCurrentDate(previousDate);
                                            setDateType("day")
                                        } else {
                                            setDateType("day")
                                            setTimeout(() => {
                                                setCurrentDate(previousDate);
                                            }, 300);
                                        }
                                        const data: any = new FormData();
                                        data.append('email', user.sub.email)
                                        const result = await axios.post(process.env.REACT_APP_API_URL + "api/changeDateType", data, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                "Content-Type": "application/json",
                                            }
                                        });
                                        opportuniteisDataFetch(previousDate)
                                        setPageCount(1)
                                    }
                                }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                                </svg>
                                <span className='font-bold'>
                                    {dateType == "day" ? getDateLabel(currentDate, i18n.language ? i18n.language : "en") : i18n.language == "en" ? "Last 7 days" : "Siste 7 dager"}
                                </span>
                                <svg className="mt-[4px] h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={async () => {
                                    if (currentDate) {
                                        const nextDate = new Date(currentDate);
                                        nextDate.setDate(currentDate.getDate() + 1);
                                        if (dateType == "week") {
                                            setCurrentDate(nextDate);
                                            setDateType("day")
                                        } else {
                                            setDateType("day")
                                            setTimeout(() => {
                                                setCurrentDate(nextDate);
                                            }, 300);
                                        }
                                        const data: any = new FormData();
                                        data.append('email', user.sub.email)
                                        const result = await axios.post(process.env.REACT_APP_API_URL + "api/changeDateType", data, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                "Content-Type": "application/json",
                                            }
                                        });
                                        opportuniteisDataFetch(nextDate)
                                        setPageCount(1)
                                    }
                                }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                            <button onClick={async () => {
                                try {
                                    setRefreshing(true)
                                    const year = currentDate.getFullYear();
                                    const month = currentDate.getMonth() + 1;
                                    const day = currentDate.getDate();
                                    const data: any = new FormData();
                                    data.append('email', user.sub.email)
                                    data.append('companyId', user.sub.companyId)
                                    data.append('year', year)
                                    data.append('month', month)
                                    data.append('day', day)
                                    const result = await axios.post(process.env.REACT_APP_API_URL + "api/refreshOpportunities", data, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                            "Content-Type": "application/json",
                                        }
                                    });
                                    if (result.status === 200) {
                                        await opportuniteisDataFetch(currentDate)
                                        setPageCount(1)
                                        setDateType("day")
                                    }
                                    setRefreshing(false)
                                } catch (error) {
                                    setRefreshing(false)
                                }
                            }}>
                                {
                                    isRefreshing ? <svg aria-hidden="true" className="mr-2 text-gray-200 w-7 h-7 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg> : <RefreshIcon className="w-7 h-7"></RefreshIcon>
                                }
                            </button>
                        </div>
                        <div className='text-[15px]'>
                            {t("Agent_Match_Maker_Description")}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap m-5'>
                {
                    isRefreshing ? <div className='flex items-center justify-center w-full h-[60vh] text-2xl whitespace-pre text-black'>{t("loading_opportunity_description")}</div> : opportunities.length <= 0 ? <div className='flex items-center justify-center w-full h-[60vh] text-2xl text-black'>{t("no_opportunity_description")}</div> :
                        opportunities.map((opportunity, index: number) => {
                            return (index < 21 * pageCount && index >= 21 * (pageCount - 1) || opportunities.length < 21) && (
                                <div
                                    key={index}
                                    className={`flex items-start  box-sizing border-box shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[4px] py-[10px] pt-0 ${isChatWindowClosed ? "w-[calc(33.3%-20px)] " : "w-[calc(50%-20px)]"} m-[10px]`}
                                >
                                    <div className={`${opportunity.color} text-white w-[15px] font-bold flex-none rounded-tl rounded-tb`}>{opportunity.relevanceScore}</div>
                                    <div className='flex flex-col text-black pl-3 w-[100%] h-[100%]'>
                                        <div className='relative flex'>
                                            <div className='flex w-[90%]' onMouseEnter={() => { setTooltipVisible(true); setTooltipIndex(index) }}
                                                onMouseLeave={() => { setTooltipVisible(false); setTooltipIndex(null); }}>
                                                <span className='text-[18px] font-bold text-left cursor-pointer' onClick={() => { isWebsite(opportunity.tenderURL) ? window.open(opportunity.tenderURL, '_blank') : null; }}>{opportunity.tenderText}</span>
                                                {tooltipVisible && tooltipIndex == index && (
                                                    <>
                                                        <div className={`absolute top-[calc(100%+10px)] left-1/2 transform -translate-x-1/2 -mt-2`}>
                                                            <div className="w-8 h-8 transform rotate-45 bg-black border-t-2 border-l-2 border-black border-solid"></div>
                                                        </div>
                                                        <div className={`absolute z-50 top-[calc(100%+10px)] bg-white border border-gray-200 p-4 rounded-lg shadow-md safari-scroll max-h-48 scrollbar-hide p-3 w-[100%]`}>
                                                            {opportunity.shortDescription}
                                                        </div>

                                                    </>
                                                )}
                                            </div>
                                            <div ref={ref => (refs.current[index] = ref)} className="relative w-[50px]" onMouseLeave={() => {
                                                setActiveDropdownIndex(null)
                                            }}>
                                                <div className='w-[45px] h-[45px] relative'>
                                                    {opportunity?.isAddedToWorkSpace == true || clickedHeartIndex.indexOf(opportunity?.id) > -1 ? <ClickedHeart className="z-20 w-[60%] h-[60%] absolute top-[35%] right-[20%] cursor-pointer" /> : <NormalHeart className="z-20 w-[60%] h-[60%] absolute top-[35%] right-[20%] cursor-pointer" onClick={(e) => addToWorkSpace(opportunity, opportunity?.id, null)} onMouseOver={() => setHover(opportunity?.id)} onMouseLeave={() => setHover(-1)} />}
                                                    {hoverHeartIndex == opportunity?.id ? <HoverHeart className="z-10 w-[100%] h-[100%] absolute top-[15%] right -[25%] cursor-pointer" /> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-[5px] flex justify-center'>
                                            <div className='w-full'>
                                                <div className='max-w-[100%] text-left prose text-xl text-black text-[18px]'>{opportunity.customerName}</div>
                                            </div>
                                        </div>
                                        <div className='flex items-end justify-between'>
                                            <div className='block'>
                                                <div className='text-left text-black'>
                                                    {t("Deadline")}{" "}
                                                    {
                                                        getRemainingDaysAndHours(opportunity.tenderDate) == null ? t("N/A") : getRemainingDaysAndHours(opportunity.tenderDate)?.remainingDays + " " + t("days") + " " + getRemainingDaysAndHours(opportunity.tenderDate)?.remainingHours + " " + t("hours")
                                                    }
                                                </div>
                                                <div className='ml-auto text-left text-black'>
                                                    {t("Est. Value")}{" "}
                                                    {opportunity?.estimatedValueAmount ? parseFloat(opportunity?.estimatedValueAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + opportunity?.estimatedValueCurrencyCode : t("N/A")}
                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: `${opportunity.cartTextBGColor}` }} className={`p-1 rounded-lg min-w-[32px] h-8 mr-3 font-bold text-center text-white align-middle text-[16px]`} onMouseOver={() => {
                                                setOverIndex(opportunity.id)
                                            }} onMouseOut={() => { setOverIndex(-1) }}>
                                                {overIndex == opportunity?.id ? opportunity?.cartTextHover : opportunity?.cartText}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            );
                        }
                        )
                }

                {!isRefreshing && opportunities.length >= 21 &&
                    (
                        <div className='flex w-[90%] h-[50px] text-base justify-end'>
                            <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`} onClick={() => {
                                if (pageCount == 1) return
                                setPageCount(pageCount - 1)
                            }}><svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            {opportunities.map((opportunity, index) => {
                                return index % 21 === 0 && <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex ${pageCount === index / 21 + 1 ? "text-white bg-main_color_2" : "text-black bg-white"} justify-center items-center text-center border rounded-md cursor-pointer`} key={index / 21} onClick={() => {
                                    setPageCount(index / 21 + 1)
                                    scrollToTop()
                                    console.log(pageCount)
                                }}>{index / 21 + 1}</div>
                            })}
                            <div className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`} onClick={() => {
                                if (pageCount >= opportunities.length / 21) return
                                setPageCount(pageCount + 1)
                            }}><svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path>
                                </svg></div>
                        </div>
                    )
                }
            </div>
        </div>
    );

}

interface CustomToastProps {
    currentStep: number;
}

// Your custom toast component with TypeScript
const CustomToast: React.FC<CustomToastProps> = ({ currentStep }) => {
    console.log("currentStep", currentStep)
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex items-center p-1'>
                {currentStep === 1 ? <Icon2 className='animate-spin' /> : <Icon3 />} &nbsp;&nbsp;{t("Documents downloaded")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 2 ? <Icon2 className='animate-spin' /> : currentStep > 2 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("AI learning the documents")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 3 ? <Icon2 className='animate-spin' /> : currentStep > 3 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Insights")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 4 ? <Icon2 className='animate-spin' /> : currentStep > 4 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Finding_documents_for_submission")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 5 ? <Icon2 className='animate-spin' /> : currentStep > 5 ? <Icon3 /> : <Icon1 />} &nbsp;&nbsp;{t("Summaries")}
            </div>
        </div>
    )
}