import React, { useEffect, useState } from 'react'
import "../../../App.css"
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getFirstSentence, getColorOfCompilanceScore, getTextFromCell, removeTextofCell, parseJWT } from '../../../utils';
import i18n from '../../../i18n';
import $ from 'jquery'

const { default: axios } = require('axios');


interface AgentWinProps {
    complianceData: any[]
    setComplianceData: any
    selectedTableData: any
    setSelectedTableData: any
    setCompilanceSaveClicked: any
    setCurrentSlide: any
}

export const AgentWin: React.FC<AgentWinProps> = ({ complianceData, setComplianceData, selectedTableData, setSelectedTableData, setCompilanceSaveClicked, setCurrentSlide }) => {
    const { t } = useTranslation();
    const [sortField, setSortField] = useState<string>('')
    const [isAscendingSortOrder, setIsAscendingSortOrder] = useState<boolean>(false)
    const [scores, setScores] = useState<any[]>([]);
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const handleSort = (field: string) => {
        let isAscending = sortField === field && !isAscendingSortOrder;
        if (scores.length == 0) {
            toast.warning(t("Sort can be done after we have score informations!"), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
            return;
        }
        if (field == 'no') {
            const sortedData = [...complianceData].sort((a, b) => {
                if (parseInt(a[field]) < parseInt(b[field])) return isAscending ? 1 : -1;
                if (parseInt(a[field]) > parseInt(b[field])) return isAscending ? -1 : 1;
                return 0;
            });

            setComplianceData(sortedData);
            const sortedScores = sortedData.map(data => {
                const index = complianceData.indexOf(data);
                return scores[index];
            });
            setScores(sortedScores);
        } else if (field == 'score') {
            const sortedData = [...complianceData];
            sortedData.sort((a, b) => {
                const scoreA = parseInt(scores[complianceData.indexOf(a)]?.score);
                const scoreB = parseInt(scores[complianceData.indexOf(b)]?.score);

                if (scoreA < scoreB) {
                    return isAscending ? 1 : -1;
                } else if (scoreA > scoreB) {
                    return isAscending ? -1 : 1;
                } else {
                    return 0;
                }
            });
            setComplianceData(sortedData);
            const sortedScores = sortedData.map(data => {
                const index = complianceData.indexOf(data);
                return scores[index];
            });
            setScores(sortedScores);
        }
        setSortField(field);
        setIsAscendingSortOrder(isAscending);
    };

    const handleRowToggle = (index: number) => {
        setComplianceData((prevData) => {
            return prevData.map((item, i) => {
                if (index === i) {
                    return { ...item, collapsed: !item.collapsed };
                }
                return item;
            });
        });
    };

    const handleEditAnswer = (index: number) => {
        setComplianceData((prevData) => {
            return prevData.map((item, i) => {
                if (index === i) {
                    return { ...item, isEdit: !item.isEdit };
                }
                return item;
            });
        });
    }

    const handleRegenerateScore = async (index: number) => {
        setComplianceData((prevData) => {
            return prevData.map((item, i) => {
                if (index === i) {
                    return { ...item, isRegenerate: true };
                }
                return item;
            });
        });
        const new_score: any = await getCompilanceScore(complianceData[index]?.requirement, complianceData[index]?.answer)
        const updatedScores = scores.map((item, i) => {
            if (i == index) {
                return {
                    ...item, score: new_score?.score, score_evaluation: new_score?.score_evaluation
                }
            }
            return item;
        })
        setScores(updatedScores);

        updatedScores.map((score: any, index) => {
            setComplianceData((prevData) => {
                return prevData.map((item, i) => {
                    if (index === i) {
                        return { ...item, collapsed: score?.score == '10' ? true : false, isEdit: false, isRegenerate: false };
                    }
                    return item;
                });
            });
        })
    }

    const getCompilanceScore = async (requirement, answer) => {
        try {
            const language = i18n.language ? i18n.language : "en"
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getCompilanceScore", {
                'requirement': requirement,
                "answer": answer,
                "language": language
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            return {
                "score": result.data.score,
                "score_evaluation": result.data.score_evaluation
            }
        } catch (error) {
            console.error("Error fetching score:", error);
            return 0; // Default score if there's an error
        }
    };
    useEffect(() => {
        // Fetch scores for each requirement when complianceData changes
        console.log('complianceData', complianceData)
        const fetchScores = async () => {
            const newScores = await Promise.all(
                complianceData.map((data) => getCompilanceScore(data.requirement, data.answer))
            );
            setScores(newScores);
            newScores.map((score: any, index) => {
                setComplianceData((prevData) => {
                    return prevData.map((item, i) => {
                        if (index === i) {
                            return { ...item, collapsed: score?.score == '10' ? true : false, isEdit: false };
                        }
                        return item;
                    });
                });
            })
        };
        if (scores.length != complianceData.length)
            fetchScores();
    }, [complianceData]);
    return (
        <>
            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                <div className='flex items-center font-medium text-black text-xl text-left ml-2 mt-[5px]'>
                    <img src={process.env.PUBLIC_URL + '/img/Winner_page4.png'} width={50} height={50} className='mr-[10px]'>
                    </img>
                    <div>
                        <div className='flex'>
                            <div className="text-xl font-bold text-left text-black">{t('Agent Win')}</div>
                        </div>
                        <div className='text-[15px]'>
                            {t("Agent_Win_Description")}
                        </div>
                    </div>

                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto h-[300px] md:h-[calc(100vh-135px)] safari-scroll scrollbar-hide">
                    {
                        complianceData && complianceData.length > 0 ? <>
                            <table className="w-full text-black table-auto">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='flex items-center justify-center ' onClick={() => handleSort('no')}>
                                            {t("No")}
                                            {sortField === 'no'
                                                ? (
                                                    <>
                                                        {parseInt(complianceData[0]?.no) < parseInt(complianceData[1]?.no) ? (
                                                            <svg className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 15 12 9 18 15" /></svg>
                                                        ) : (
                                                            <svg className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
                                                        )}
                                                    </>
                                                )
                                                : null}
                                        </th>
                                        <th >{t("Requirement")}</th>
                                        <th >{t("Answer")}</th>
                                        <th className='flex items-center justify-center ' onClick={() => handleSort('score')}>Score
                                            {sortField === 'score'
                                                ? (
                                                    <>
                                                        {parseInt(complianceData[0]?.no) < parseInt(complianceData[1]?.no) ? (
                                                            <svg className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 15 12 9 18 15" /></svg>
                                                        ) : (
                                                            <svg className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
                                                        )}
                                                    </>
                                                )
                                                : null}
                                        </th>
                                        <th>{t("Score evaluation")}</th>
                                        <th>{t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        complianceData && complianceData.map((data, index: any) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='mr-[10px]'>
                                                        {
                                                            scores.length > 0 ? data?.collapsed == true ? <svg onClick={() => handleRowToggle(index)} className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>
                                                                : <svg onClick={() => handleRowToggle(index)} className="w-6 h-6 text-black" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg> : <></>
                                                        }
                                                    </td>
                                                    <td className='p-[20px]'>{data?.no ? data?.no : (index + 1)}</td>
                                                    <td className='p-[20px] text-left'>{data?.collapsed == true ? getFirstSentence(data?.requirement) : data?.requirement}</td>
                                                    <td className='p-[20px] text-left'>
                                                        {
                                                            data?.isEdit == true ? <textarea id="companyInformation" rows={3} className="scrollbar-hide block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-[10px]" placeholder={t("Answer")} value={data?.answer} onChange={(e) => {
                                                                setComplianceData((prevData) => {
                                                                    return prevData.map((item, i) => {
                                                                        if (index === i) {
                                                                            return { ...item, answer: e.target.value };
                                                                        }
                                                                        return item;
                                                                    });
                                                                });
                                                            }}></textarea> : data?.collapsed == true ? getFirstSentence(data?.answer) : data?.answer
                                                        }
                                                    </td>
                                                    <td className='p-[20px] font-bold'><span className={`rounded-[5px] px-[15px] py-[5px] text-white ${getColorOfCompilanceScore(scores[index]?.score)}`}>{scores[index]?.score}</span></td>
                                                    <td className='p-[20px] text-left'>{scores[index]?.score_evaluation == "null" || scores[index]?.score_evaluation == "Null" ? "" : scores[index]?.score_evaluation}</td>
                                                    <td className='p-[20px] '>{
                                                        scores.length > 0 ? scores[index]?.score == '10' ? '' : <span className='flex'>
                                                            {
                                                                data?.isEdit ? <svg onClick={() => { handleEditAnswer(index) }} className="w-6 h-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />  <polyline points="17 21 17 13 7 13 7 21" />  <polyline points="7 3 7 8 15 8" /></svg> : <svg onClick={() => { handleEditAnswer(index) }} className="text-black h-7 w-7" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
                                                            }
                                                            {
                                                                data?.isRegenerate ? <div className="flex items-center text-left	space-x-8 text-xl text-black ml-[15px]">
                                                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                    </svg>
                                                                    <span className="sr-only">Loading...</span>
                                                                </div> : <svg onClick={() => { handleRegenerateScore(index) }} className="ml-[15px] h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polyline points="23 4 23 10 17 10" />  <polyline points="1 20 1 14 7 14" />  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" /></svg>
                                                            }
                                                        </span> : <></>
                                                    }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {scores.length > 0 && ( // Add this condition to display the total score row when there are scores available
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="font-bold text-right">Total Score:</td>
                                            <td className="p-[20px] font-bold">
                                                {scores.reduce((total, score) => parseInt(total) + parseInt(score?.score ? score?.score : 0), 0)}
                                                /
                                                {10 * scores.length}
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="flex items-center justify-end pt-6 border-t border-solid rounded-b border-slate-200">
                                {user?.sub?.membership == "trial" ?
                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={() => {
                                            toast.error(t("Please upgrade your license to use this functionality"), {
                                                position: "top-right",
                                                autoClose: 1000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: 0,
                                            })
                                        }}
                                    >
                                        {t("Complete Update")}
                                    </button>
                                    :
                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={() => {
                                            setCurrentSlide(2)
                                            setCompilanceSaveClicked(true)
                                        }}
                                    >
                                        {t("Complete Update")}
                                    </button>}

                            </div>
                        </> : <div className="text-black space-y-4 text-left pt-[16%] pl-[25%]">
                            <div className="font-bold text-[30px]">{t('AgentBidChamp_Guide')}</div>
                            <div className="text-[20px] leading-relaxed">
                                {t('AgentBidChamp_Sentence_1')}
                            </div>
                            <div className="text-[20px] leading-relaxed">
                                {t('AgentBidChamp_Sentence_2')}
                            </div>
                            <div className="text-[20px] leading-relaxed">
                                {t('AgentBidChamp_Sentence_3')}
                            </div>
                        </div>
                    }

                </div>
                {/*footer*/}

            </div>
        </>
    );

}