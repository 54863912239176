import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const { default: axios } = require('axios');
import { parseJWT, getUrlsFromText, isWebsite, getRemainingDaysAndHours } from '../../../utils';
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/complete.svg";
interface ConfirmModalProps {
  setCurrentSlide: any
  documentsCount: any
  url: any
  refreshData: any
  setIsFromModal: any
  promptDataFetch: any
  selectedFolderID: any
  tenderList: any
  setTenderList: any
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ setCurrentSlide, documentsCount, url, refreshData, setIsFromModal, promptDataFetch, selectedFolderID, tenderList, setTenderList }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [count, setCount] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const handleFilesSelected = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
    uploadFiles(selectedFiles)
  };
  useEffect(() => {
    setCount(documentsCount)
  }, [])
  const closeModal = () => {
    setIsModalOpen(false);
    refreshData()
  };
  // Handle files selected via drag-and-drop
  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleFilesSelected(acceptedFiles);
  }, [handleFilesSelected]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Handle files selected via the traditional input
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFilesSelected(Array.from(event.target.files));
    }
  };
  let user = parseJWT(localStorage.getItem("tender_auth"));
  const uploadFiles = async (selectedFiles) => {
    let tenders = tenderList.filter(item => item.id == selectedFolderID)
    let tender = tenders[0]
    const formData = new FormData();
    formData.append("id", tender.id);
    formData.append("email", user.sub.email);
    formData.append('tab', tender.tab);
    formData.append('folder', tender.title);
    selectedFiles.forEach((file) => {
      formData.append('file', file);
    });
    try {
      const id = toast(
        <div className='flex items-center p-1'><Icon2 className='animate-spin' />{t("Uploading Document Files")}</div>,
        {
          autoClose: false, // Prevent auto-close to control it manually
          closeOnClick: true,
          draggable: false,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: 0
        }
      );
      const response = await axios.post(process.env.REACT_APP_API_URL + "api/uploadDocument", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          "Content-Type": "application/json",
        }
      });
      toast.update(id, {
        render: <div className='flex items-center p-1'><Icon3 />{t("Uploading Docuemnt Files")}</div>
      });
      setTimeout(() => {
        toast.dismiss(id);
        console.log(selectedFiles)
        console.log(selectedFiles.length)
        setCount(selectedFiles.length)
      }, 2000);
      const data = new FormData();
      data.append("companyId", user.sub.companyId);
      data.append("tab", tender.tab);
      const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          "Content-Type": "application/json",
        }
      });
      setTenderList(result.data.data);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <>
      {isModalOpen && (<div className="text-black fixed inset-0 flex items-center justify-center bg-opacity-75 p-4 z-[100]">
        <div className="flex flex-col w-full max-w-2xl bg-white rounded-lg shadow-lg">
          {/* Modal Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <div className="flex items-center">
              {/* Placeholder for Logo */}
              <img src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} width={50} height={50} className='mr-[10px]'></img>
              <h2 className="text-3xl font-semibold text-black">{t("Agent Match Maker")}</h2>
            </div>
          </div>
          {/* Modal Body */}
          {count > 0 ?
            <>
              <div className="flex-1 p-4 mb-6">
                <p className="mb-4 whitespace-pre">
                  {t("I found")} {count} {t("after documents founded")}
                </p>
              </div>
              <div className="flex justify-center mb-6 space-x-4 text-[22px]">
                <button
                  onClick={closeModal}
                  className="px-6 py-2 font-semibold text-gray-600 bg-gray-200 border border-gray-400 rounded active:bg-gray-300 active:border-gray-500 active:text-gray-700"
                  style={{ width: 180, height: 70 }}
                >
                  {t("Later")}
                </button>
                <button
                  onClick={() => {
                    setIsFromModal(true);
                    setCurrentSlide(1)
                    closeModal()
                  }}
                  className="px-6 py-2 font-semibold text-white bg-green-500 rounded active:bg-green-600 active:border-green-700 active:text-white"
                  style={{ backgroundColor: '#55B685', width: 180, height: 70 }}
                >
                  {t("Get Insights!")}
                </button>
              </div>
            </>
            :
            <>
              <div className="flex-1 p-4 mb-6 overflow-y-auto">
                <p className="mb-4">
                  {t("not found documents")}
                  <a href={url} target="_blank" className="font-bold text-blue-900 underline">{t("Link")}</a>
                </p>
                <div className="flex mb-6 space-x-4">
                  {/* Left Column: List of Files */}
                  <div className="w-1/2 p-2 border-r">
                    {files.length > 0 && (
                      <ul className="text-sm">
                        {files.map((file, index) => (
                          <li className="mb-1" key={index}>{file.name}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* Right Column: Upload Area */}
                  <div className="flex flex-col items-center justify-center w-1/2 p-2 border-l">
                    <div className="w-full p-4 text-center bg-gray-100 border-2 border-gray-300 border-dashed rounded-lg">
                      <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle} onClick={() => {
                        return false;
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 mx-auto mb-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="17 8 12 3 7 8" />
                          <line x1="12" y1="3" x2="12" y2="15" />
                        </svg>
                        <input {...getInputProps()} />
                        <p>{t("Drag and drop files here")}</p>
                      </div>
                      <p className="mt-2">{t("or")}</p>
                      <input type="file" id="manualFileUpload" multiple onChange={handleFileChange} style={fileInputStyle} />
                      <p onClick={() => {
                        let inputDiv = document.getElementById("manualFileUpload")
                        inputDiv?.click();
                      }} className="mt-2 font-bold text-blue-900 underline">{t("Choose from Computer")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          {/* Modal Footer */}
          <div className="flex justify-end p-4 mt-auto border-t">
            <button
              onClick={closeModal}
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};
// Styles (for simplicity, you can also use CSS classes)
const dropzoneStyle: React.CSSProperties = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  marginBottom: '20px'
};

const fileInputStyle: React.CSSProperties = {
  display: 'none',
  marginTop: '10px'
};
export default ConfirmModal;