// TreeItem.tsx
import React, { useState } from 'react';

interface TreeItemProps {
    id: string, 
    label: string;
    isChecked: boolean;
    onCheckboxChange: (isChecked: boolean) => void;
    children?: React.ReactNode;
}

const TreeItem: React.FC<TreeItemProps> = ({ id, label, isChecked, onCheckboxChange, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandToggle = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div>
            <div className="flex items-start">
                {children && (
                    <button className="mr-2 focus:outline-none]" onClick={handleExpandToggle}>
                        {isExpanded ? '-' : '+'}
                    </button>
                )}
                <label className="flex items-baseline space-x-2">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={isChecked}
                        onChange={(e) => onCheckboxChange(e.target.checked)}
                    />
                    <span>{`${id} ${label}`}</span>
                </label>
            </div>
            {isExpanded && <div className="ml-4">{children}</div>}
        </div>
    );
};

export default TreeItem;