import React, { useEffect, useState } from 'react';
import i18n from '../../../i18n';
const { default: axios } = require('axios');
type DropdownProps = {
    documents: string[];
    selectedFolderID: string
    description: string
    getDocumentsForSubmission: any
    clearDropdownData: any
    dropdownIndex: any
    dropdownDoc: any
    index: number
};

const Dropdown: React.FC<DropdownProps> = ({ documents, selectedFolderID, description, getDocumentsForSubmission, clearDropdownData, dropdownIndex, dropdownDoc, index }) => {
    const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const language = i18n.language || "en"

    useEffect(() => {
        if (dropdownDoc != null && index === dropdownIndex) {
            setSelectedDocument(dropdownDoc)
            // setIsOpen(true);
        }
    }, [dropdownDoc])
    const handleSelect = async (document: string) => {
        setSelectedDocument(document);
        setIsOpen(false);  // Close the dropdown after selection
        clearDropdownData()
        if (document !== 'Select a document' && document !== 'Velg et dokument') {
            const formData: any = new FormData();
            formData.append('tender_id', selectedFolderID)
            formData.append('doc_id', document)
            formData.append('description', description)
            formData.append('status', 'Uncomplete')
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveChangesSubmission", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                getDocumentsForSubmission("normal")
            }
        }
    };

    return (
        <div className="relative inline-block w-full">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full p-2 text-black bg-white-600 rounded-lg shadow focus:outline-none focus:ring text-left"
            >
                {selectedDocument ? selectedDocument : language == 'en' ? 'Select a document' : 'Velg et dokument'}
            </button>

            {isOpen && (
                <ul className="absolute z-10 w-full h-[300px] safari-scroll mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                    {documents.map((document, index) => (
                        <li
                            key={index}
                            onClick={() => handleSelect(document)}
                            className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                        >
                            {document}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
