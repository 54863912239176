import React, { useEffect, useState, useRef } from 'react'
import "../../../App.css"
import { parseJWT } from '../../../utils'
import { ReactComponent as DotsIcon } from "../../../assets/svg/threedots.svg";
import { ReactComponent as InsightRefreshIcon } from "../../../assets/svg/insight_refresh.svg";
import { LoadingBar } from '../../LoadingBar';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DocumentsTable from './DocumentsTable';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
const { default: axios } = require('axios');
interface InsightsProps {
    openedDocument: string,
    promptList: any[],
    selectedDocumentList: any[],
    displayModal: any,
    handleEditPrompt: any,
    handleDelete: any,
    selectedFolderID: any
    promptDataFetch: any
    summaries: any[]
    handleDocumentClick: any
    displayInsightDeleteModal: any
    isFromModal: any
    setIsFromModal: any
    tenderList: any
    setTenderList: any
    setOpenedStatus: any
    handleSelectedFolder: any
    selectedTab: any
    setTabName: any
    setSummaryList: any,
    setCurrentSlide: any,
    setAutoFilling: any
    socketId: any
    companyKnowledgeBaseId: string
}

export const Insights: React.FC<InsightsProps> = ({ setSummaryList, openedDocument, promptList, selectedDocumentList, displayModal, handleEditPrompt, handleDelete, selectedFolderID, promptDataFetch, summaries, handleDocumentClick, displayInsightDeleteModal, isFromModal, setIsFromModal, tenderList, setTenderList, setOpenedStatus, handleSelectedFolder, selectedTab, setTabName, setCurrentSlide, setAutoFilling, socketId, companyKnowledgeBaseId }) => {
    useEffect(() => {
        if (isFromModal == true) {
            setTimeout(() => {
                syncAnswers()
            }, 1000)
        }
    }, [isFromModal]);
    useEffect(() => {
    }, [openedDocument])
    // setRunSync
    const [insightPromptList, setPromptList] = useState<any[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [isRefreshing, setRefreshing] = useState(false)
    const [runSync, setRunSync] = useState(false)
    const [documents, setDocuments] = useState<any>(null)
    const { t } = useTranslation();
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;

    const refs = useRef<(HTMLDivElement | null)[]>(Array(insightPromptList.length).fill(null));
    const createMarkup = (text) => {
        // Convert the Markdown text to HTML using marked
        const rawMarkup = marked(text);
        // Sanitize the HTML to prevent XSS attacks
        const cleanMarkup = DOMPurify.sanitize(rawMarkup);
        return { __html: cleanMarkup };
    };
    const toggleDropdown = (index, event) => {
        const currentRef = refs?.current[index]
        if (currentRef &&
            !currentRef.contains(event.target as Node)) {
            // Clicked outside all dropdowns, handle the close action here
            setActiveDropdownIndex(null);
        } else {
            setActiveDropdownIndex(index === activeDropdownIndex ? null : index);
        }
    };
    useEffect(() => {
        if (promptList) {
            setPromptList(promptList)
        }
        if (selectedFolderID && selectedFolderID != "all_folders") {
            getDocumentsForSubmission("normal")
        }
    }, [promptList])
    /**
     * A function that handles getting prompt answers asynchronously.
     *
     * @param {string} text - The text input for the prompt.
     * @param {number} index - The index of the prompt.
     * @param {string} id - The ID of the prompt.
     * @param {string} isGlobalInsight - Flag indicating if the prompt is a global insight.
     * @return {Promise<void>} A Promise that resolves once the prompt answer is retrieved.
     */
    const getPromptAnswer = async (text: string, index: number, id: string, isGlobalInsight: string) => {
        setPromptList(prevList => {
            const newList = [...prevList];
            newList[index].isLoading = true;
            return newList;
        });
        const documentsForCompanyKnowledgebade: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document)
        const documentsForWorkspace: any[] = selectedDocumentList.filter(item => item?.tabName == "tab_1").map(item => item.document)
        isGlobalInsight = isGlobalInsight ? isGlobalInsight : "No"
        let params = {
            email: user.sub.email,
            companyId: user.sub.companyId,
            question: text + "\n",
            selectedDocumentList: documentsForWorkspace,
            promptId: id,
            selectedFolderID: selectedFolderID,
            tender_id: selectedFolderID,
            companyKnowledgeBaseId: companyKnowledgeBaseId,
            documentsForCompanyKnowledgebade: documentsForCompanyKnowledgebade,
            isGlobalInsight: isGlobalInsight
        }
        const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                'Content-Type': 'application/json'
            }
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let isStarted = false;
        setPromptList(prevList => {
            const newList = [...prevList];
            newList[index].isLoading = false;
            return newList;
        });
        while (!done) {
            const { value, done: doneReading } = await reader.read();
            const updatedList: any[] = [...promptList];
            if (!isStarted) {
                if (!updatedList[index].response || typeof updatedList[index].response === 'string') {
                    updatedList[index].response = [];
                }
                const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                if (existingIndex !== -1) {
                    updatedList[index].response[existingIndex][selectedFolderID] = '';
                } else {
                    updatedList[index].response.push({ [selectedFolderID]: '' });
                }
            }
            done = doneReading;
            const chunkValue = decoder.decode(value);
            var regex = /([^:]*)json_data:(\{.*?\})/g;
            var match = regex.exec(chunkValue);
            if (match && match.length >= 3) {
                var beforeText = match[1];
                if (beforeText) {
                    const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                    if (existingIndex !== -1) {
                        updatedList[index].response[existingIndex][selectedFolderID] += beforeText
                    }
                    setPromptList(updatedList)
                }
                var jsonData = match[2];
            } else {
                const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                if (existingIndex !== -1) {
                    updatedList[index].response[existingIndex][selectedFolderID] += chunkValue
                }
                if (!chunkValue.includes("</")) {
                    setPromptList(updatedList)
                }
            }
            isStarted = true
        }
    }

    const handlePlusButtonClick = () => {
        displayModal()
    }
    const [isLoading, setLoading] = useState(false);
    const syncAnswers = async () => {
        try {
            setRefreshing(true)
            await getInsights()
            await getDocumentsForSubmission("refresh")
            await createDocumentsSummary();
            setRefreshing(false)
            setIsFromModal(false);
        } catch (error) {
            setRefreshing(false)
            setIsFromModal(false);
        }
    }
    const getDocumentsForSubmission = async (type: any) => {
        setLoading(true)
        const formData: any = new FormData();
        formData.append('companyId', user.sub.companyId)
        formData.append('email', user.sub.email)
        formData.append('tender_id', selectedFolderID)
        formData.append('type', type)
        if (isFromModal == true) {
            formData.append('socketId', socketId)
        }
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getDocumentsForSubmission", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200 && result.data.documentsForSubmission) {
            setDocuments(result.data.documentsForSubmission)
            setLoading(false)
        }
    }
    const createDocumentsSummary = async () => {
        const formData: any = new FormData();
        formData.append('email', user.sub.email)
        formData.append("companyId", user.sub.companyId)
        formData.append('tender_id', selectedFolderID)
        if (isFromModal == true) {
            formData.append('socketId', socketId)
        }
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/createDocumentsSummary", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status == 200) {
            setSummaryList(result.data.data)
        }
    }
    useEffect(() => {
        if (selectedFolderID && selectedFolderID != "all_folders") {
            getDocumentsForSubmission("normal")
        }
    }, [selectedFolderID])
    const getInsights = async () => {
        for (let index = 0; index < insightPromptList.length; index++) {
            const prompt = insightPromptList[index];
            if (prompt.type === 'insight') {
                await getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight);
            }
        }
    }
    useEffect(() => {
        if (tenderList.filter(item => item.id === selectedFolderID)[0]?.status == "Evaluate") {
            setCurrentSlide(1)
            syncAnswers()
        }
    }, [tenderList.filter(item => item.id === selectedFolderID)[0]?.status])
    return (
        <div className='safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]' onClick={() => activeDropdownIndex ? setActiveDropdownIndex(null) : null}>
            <div className="flex items-center mt-2">
                <div className='flex items-center ml-2 text-xl font-medium text-left text-black'>
                    <img src={process.env.PUBLIC_URL + '/img/Insight_page2.png'} width={50} height={50} className='mr-[24px]'>
                    </img>
                    <div>
                        <div className='flex'>
                            <div className="text-xl font-bold text-left text-black">{t('Agent Insight')}</div>
                            <div className='flex ml-[30px]'>
                                {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ?
                                    <div className='flex items-center'>
                                        <button className="h-7 ml-[10px] cursor-wait relative group" onClick={() => {
                                            toast.error(t("Please upgrade your license to use this functionality"), {
                                                position: "top-center",
                                                autoClose: 1000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: 0,
                                            })
                                        }}> {
                                                isRefreshing ? <svg aria-hidden="true" className="mr-2 text-gray-200 w-7 h-7 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg> : <InsightRefreshIcon className='w-7 h-7' />
                                            }
                                            <div className='p-1 absolute z-50 hidden text-white bg-black top-7 flex-nowrap whitespace-nowrap group-hover:block left-[-48px] tooltiptext'>Sync Insights</div>
                                        </button>
                                        <button className="group relative pl-[5px] cursor-wait" onClick={() => {
                                            toast.error(t("Please upgrade your license to use this functionality"), {
                                                position: "top-center",
                                                autoClose: 1000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: 0,
                                            })
                                        }}>
                                            <svg className="text-black w-7 h-7" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <line x1="12" y1="5" x2="12" y2="19" />
                                                <line x1="5" y1="12" x2="19" y2="12" />
                                            </svg>
                                            <div className='p-1 absolute z-50 hidden text-white bg-black top-7 flex-nowrap whitespace-nowrap group-hover:block left-[-75px] tooltiptext'>Create new Insights</div>
                                        </button>
                                    </div>
                                    :
                                    <div className='h-7'>
                                        <button className="ml-[10px] cursor-pointer" onClick={() => syncAnswers()}> {
                                            isRefreshing ? <svg aria-hidden="true" className="mr-2 text-gray-200 w-7 h-7 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg> : <InsightRefreshIcon className='w-7 h-7' />
                                        }
                                        </button>
                                        <button className="pl-[5px] cursor-pointer" onClick={() => handlePlusButtonClick()}>
                                            <svg className="text-black w-7 h-7" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <line x1="12" y1="5" x2="12" y2="19" />
                                                <line x1="5" y1="12" x2="19" y2="12" />
                                            </svg>
                                        </button>
                                    </div>}

                            </div>
                        </div>
                        <div className='text-[15px]'>
                            {t("Agent_Insight_Description")}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {insightPromptList.filter(prompt => {
                    const targetInformationElement = document.getElementById("target_information");
                    if (targetInformationElement) {
                        const textContent = targetInformationElement.textContent;
                        if (selectedTab === 'tab_2' && (textContent === "Company Documents" || textContent === "Selskapets dokumenter")) {
                            return false
                        }
                    }


                    if (selectedFolderID === "all_folders") {
                        return true; // Include all insights for "all_folders"
                    }

                    if (selectedFolderID === prompt.tender_id ||
                        (prompt.isGlobalInsight === "Yes" && !prompt.deleted_folder_ids?.includes(selectedFolderID))) {
                        return true; // Include specific insights based on folder ID or global insights
                    }

                    return false; // Exclude other insights
                }).length > 0 && (
                        <>
                            <div className="p-6 pb-[0] text-black bg-gray-100">
                                <h1 className="text-xl font-bold text-left">{t('Insights')}</h1>
                            </div>
                            <div className='flex flex-wrap p-6 mt-[0px] pl-[0px]'>
                                {insightPromptList.map((prompt, index) => {
                                    let responseContent = ""
                                    if (Array.isArray(prompt.response)) {
                                        const existingIndex = prompt.response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                                        if (existingIndex !== -1) {
                                            responseContent = prompt.response[existingIndex][selectedFolderID]
                                        }
                                    }
                                    const contentLength = responseContent ? responseContent.length : 0;
                                    const widthClass = contentLength > 300 ? 'w-[calc(98.2%)]' : 'w-[calc(48.2%)]';
                                    const isResponseArray = Array.isArray(prompt.response);
                                    const formattedResponse = isResponseArray
                                        ? prompt.response
                                            .map(item =>
                                                item.hasOwnProperty(selectedFolderID) ? item[selectedFolderID] : ""
                                            )
                                            .join("")
                                            .replace(/```html|```/g, "")
                                            .trim()
                                        : "";
                                    if (prompt.type == 'insight') {
                                        if (selectedFolderID == "all_folders") {
                                            return <div key={index} className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[4px] ${widthClass} mb-[10px] ml-[24px]`}>
                                                <div className='flex flex-col text-black'>
                                                    <div className='flex bg-cyan-700 py-[10px] px-[25px]'>
                                                        <span className='text-[20px] font-bold text-left text-white'>{prompt.title}</span>
                                                        <div className="flex-grow"></div>
                                                        <div>
                                                            {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ?
                                                                <></> :
                                                                <button className="mr-1 cursor-pointer" onClick={() => getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight)}>
                                                                    <InsightRefreshIcon className='text-white'></InsightRefreshIcon>
                                                                </button>
                                                            }

                                                        </div>
                                                        <div className="relative" ref={ref => (refs.current[index] = ref)} onMouseLeave={() => {
                                                            setActiveDropdownIndex(null)
                                                        }}>
                                                            {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ? <DotsIcon onClick={() => {
                                                                toast.error(t("Please upgrade your license to use this functionality"), {
                                                                    position: "top-center",
                                                                    autoClose: 1000,
                                                                    hideProgressBar: true,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: 0,
                                                                })
                                                            }} className="absolute text-white cursor-wait " /> : <DotsIcon onClick={(e) => toggleDropdown(index, e)} onMouseEnter={(e) => toggleDropdown(index, e)} className="absolute text-white cursor-pointer" />}

                                                            {activeDropdownIndex === index && (user?.sub?.membership != "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended") && (
                                                                <div className="absolute right-[-174px] bg-white shadow rounded z-[1000] p-[10px] w-[150px]">
                                                                    <div className='cursor-pointer mb-[10px] hover:text-[#628ABE]' onClick={() => { setActiveDropdownIndex(null); handleEditPrompt(prompt); }}>{t("Edit Prompt")}</div>
                                                                    <div className='cursor-pointer hover:text-[#628ABE]' onClick={() => {
                                                                        setActiveDropdownIndex(null);
                                                                        displayInsightDeleteModal(prompt.id, false);
                                                                    }}>{t("Delete Prompt")}</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
                                                        {
                                                            prompt.isLoading ?
                                                                <div className='flex items-center'>
                                                                    <LoadingBar />
                                                                </div>
                                                                :
                                                                isResponseArray && formattedResponse != "" ?
                                                                    <div className='w-full'>
                                                                        <div className='max-w-[100%] text-left prose text-xl text-black' dangerouslySetInnerHTML={createMarkup(formattedResponse)}></div>

                                                                    </div>
                                                                    :
                                                                    <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            if (selectedFolderID == prompt?.tender_id) {
                                                return <div key={index} className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[4px] ${widthClass} mb-[10px] ml-[24px]`}>
                                                    <div className='flex flex-col text-black'>
                                                        <div className='flex bg-cyan-700 py-[10px] px-[25px]'>
                                                            <span className='text-[20px] font-bold text-left text-white'>{prompt.title}</span>
                                                            <div className="flex-grow"></div>
                                                            <div>
                                                                {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ?
                                                                    <button className="pr-1 cursor-wait" onClick={() => {
                                                                        toast.error(t("Please upgrade your license to use this functionality"), {
                                                                            position: "top-center",
                                                                            autoClose: 1000,
                                                                            hideProgressBar: true,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: 0,
                                                                        })
                                                                    }}>
                                                                        <InsightRefreshIcon className='text-white'></InsightRefreshIcon>
                                                                    </button> :
                                                                    <button className="mr-1 cursor-pointer" onClick={() => getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight)}>
                                                                        <InsightRefreshIcon className='text-white'></InsightRefreshIcon>
                                                                    </button>
                                                                }
                                                            </div>
                                                            <div className="relative" ref={ref => (refs.current[index] = ref)} onMouseLeave={() => {
                                                                setActiveDropdownIndex(null)
                                                            }}>
                                                                {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ? <DotsIcon onClick={() => {
                                                                    toast.error(t("Please upgrade your license to use this functionality"), {
                                                                        position: "top-center",
                                                                        autoClose: 1000,
                                                                        hideProgressBar: true,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: 0,
                                                                    })
                                                                }} className="absolute text-white cursor-wait" /> : <DotsIcon onClick={(e) => toggleDropdown(index, e)} onMouseEnter={(e) => toggleDropdown(index, e)} className="absolute text-white cursor-pointer" />}
                                                                {activeDropdownIndex === index && (user?.sub?.membership != "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended") && (
                                                                    <div className="absolute right-[-174px] bg-white shadow rounded z-[1000] p-[10px] w-[150px]">
                                                                        <div className='cursor-pointer mb-[10px] hover:text-[#628ABE]' onClick={() => { setActiveDropdownIndex(null); handleEditPrompt(prompt); }}>{t("Edit Prompt")}</div>
                                                                        <div className='cursor-pointer hover:text-[#628ABE]' onClick={() => {
                                                                            setActiveDropdownIndex(null);
                                                                            displayInsightDeleteModal(prompt.id, false);
                                                                        }}>{t("Delete Prompt")}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
                                                            {
                                                                prompt.isLoading ?
                                                                    <div className='flex items-center'>
                                                                        <LoadingBar />
                                                                    </div>
                                                                    :
                                                                    isResponseArray && formattedResponse != "" ?
                                                                        <div className='w-full'>
                                                                            <div className='max-w-[100%] text-left prose text-xl text-black' dangerouslySetInnerHTML={createMarkup(formattedResponse)}></div>

                                                                        </div>
                                                                        :
                                                                        <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            if (prompt?.isGlobalInsight == "Yes") {
                                                if (!prompt?.deleted_folder_ids?.includes(selectedFolderID)) {

                                                    return <div key={index} className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[4px] ${widthClass} mb-[10px] ml-[24px]`}>
                                                        <div className='flex flex-col text-black'>
                                                            <div className='flex bg-cyan-700 py-[10px] px-[25px]'>
                                                                <span className='text-[20px] font-bold text-left text-white'>{prompt.title}</span>
                                                                <div className="flex-grow"></div>
                                                                <div>
                                                                    {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ?
                                                                        <button className="pr-1 cursor-wait" onClick={() => {
                                                                            toast.error(t("Please upgrade your license to use this functionality"), {
                                                                                position: "top-center",
                                                                                autoClose: 1000,
                                                                                hideProgressBar: true,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: 0,
                                                                            })
                                                                        }}>
                                                                            <InsightRefreshIcon className='text-white'></InsightRefreshIcon>
                                                                        </button> :
                                                                        <button className="mr-1 cursor-pointer" onClick={() => getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight)}>
                                                                            <InsightRefreshIcon className='text-white'></InsightRefreshIcon>
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <div className="relative" ref={ref => (refs.current[index] = ref)} onMouseLeave={() => {
                                                                    setActiveDropdownIndex(null)
                                                                }}>
                                                                    {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ? <DotsIcon onClick={() => {
                                                                        toast.error(t("Please upgrade your license to use this functionality"), {
                                                                            position: "top-center",
                                                                            autoClose: 1000,
                                                                            hideProgressBar: true,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: 0,
                                                                        })
                                                                    }} className="absolute text-white cursor-wait" /> : <DotsIcon onClick={(e) => toggleDropdown(index, e)} onMouseEnter={(e) => toggleDropdown(index, e)} className="absolute text-white cursor-pointer" />}
                                                                    {activeDropdownIndex === index && (user?.sub?.membership != "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended") && (
                                                                        <div className="absolute right-[-174px] bg-white shadow rounded z-[1000] p-[10px] w-[150px]">
                                                                            <div className='cursor-pointer mb-[10px] hover:text-[#628ABE]' onClick={() => { setActiveDropdownIndex(null); handleEditPrompt(prompt); }}>{t("Edit Prompt")}</div>
                                                                            <div className='cursor-pointer hover:text-[#628ABE]' onClick={() => {
                                                                                setActiveDropdownIndex(null);
                                                                                displayInsightDeleteModal(prompt.id, true);
                                                                            }}>{t("Delete Prompt")}</div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
                                                                {
                                                                    prompt.isLoading ?
                                                                        <div className='flex items-center'>
                                                                            <LoadingBar />
                                                                        </div>
                                                                        :
                                                                        isResponseArray && formattedResponse != "" ?
                                                                            <div className='w-full'>
                                                                                <div className='max-w-[100%] text-left prose text-xl text-black' dangerouslySetInnerHTML={createMarkup(formattedResponse)}></div>

                                                                            </div>
                                                                            :
                                                                            <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                        }
                                    }
                                })}
                            </div>
                        </>
                    )}

                <DocumentsTable setRunSync={setRunSync} setAutoFilling={setAutoFilling} setcurrentSlide={setCurrentSlide} setTabName={setTabName} selectedTab={selectedTab} getDocumentsForSubmission={getDocumentsForSubmission} documentsForSubmission={documents} isLoading={isLoading} selectedFolderID={selectedFolderID} handleDocumentClick={handleDocumentClick} tenderList={tenderList} setTenderList={setTenderList} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder}></DocumentsTable>

                {
                    summaries && summaries.length > 0 ? <><div className="p-6 pb-[0] text-black bg-gray-100">
                        <h1 className="text-xl font-bold text-left">{t('Summaries')}</h1>
                    </div>
                        <div className='flex flex-wrap m-[20px] ml-[0px]'>
                            {summaries.map((summary, index) => {
                                const responseContent = summary.summary?.trim();
                                const contentLength = responseContent ? responseContent.length : 0;
                                const widthClass = contentLength > 300 ? 'w-[calc(98.2%)]' : 'w-[calc(47%)]';
                                return (
                                    <div key={index} className={`relative box-sizing border-box bg-main_bg shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[4px] ${widthClass} mb-[10px] ml-[24px]`}>
                                        <div className='flex flex-col text-black'>
                                            <div className='flex bg-cyan-700 w-full py-[10px] px-[25px]' style={{ 'wordBreak': 'break-word' }}>
                                                <span className='text-[20px] font-bold text-left text-white'>{summary.title != "" ? summary.title : summary.document}</span>
                                            </div>
                                            <div className='my-[20px] flex justify-center px-[25px] safari-scroll scrollbar-hide'>
                                                <div className='w-full'>
                                                    <div className='max-w-[100%] text-left prose text-xl text-black overflow-x-scroll scrollbar-hide'
                                                        dangerouslySetInnerHTML={createMarkup(summary.summary.replace("```html", '').replace("```", "").trim())}></div>
                                                </div>
                                            </div>
                                            <div className='px-[25px] pb-[10px] flex text-left text-black underline break-words cursor-pointer' style={{ 'wordBreak': 'break-word' }} onClick={() => handleDocumentClick(summary.document)}>
                                                {summary.document}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div></> : <></>
                }


            </div>
        </div >
    );

}